<template>
  <div>
    <v-card elevation="0" rounded="lg">
      <v-container class="d-flex flex-wrap justify-space-between">
        <div class="d-flex flex-wrap justify-center">
          <span class="deep-purple--text text--darken-3 text-body-1 font-weight-bold mt-1">
            <v-icon color="deep-purple darken-4" class="mt-n1">mdi-collage</v-icon>
            {{ $t("message.PowerSummarizer_Modes") }}:
          </span>
          <div class="ml-xl-6 ml-lg-6 ml-md-3 mb-n3 mt-xl-0 mt-lg-0 mt-md-0">
            <v-tabs v-model="modesTagsSelected" color="deep-purple" height="38">
              <v-tab v-for="(item, index) in modesTags" :key="index" @change="handlePowerSummarizerChange">
                <span class="text-capitalize font-weight-bold text-body-1 mb-1">{{ item.tagName }}</span>
              </v-tab>
            </v-tabs>
          </div>
          <div class="d-flex mt-4 mt-xl-1 mt-lg-1 mt-md-1 ml-2 ml-xl-10 ml-lg-10 ml-md-10" v-if="modesTagsSelected == 0">
            <span class="deep-purple--text text--darken-3 text-body-1 font-weight-bold" v-if="!$vuetify.breakpoint.smAndDown">
              {{ $t("message.PowerSummarizer_SummaryLength") }}:
            </span>
            <span class="deep-purple--text text--darken-3 text-body-1 font-weight-bold" v-else>
              {{ $t("message.PowerSummarizer_Length") }}:
            </span>
            <div class="mx-4" style="width: 200px; height: 24px;">
              <v-slider
                v-model="lengthSlider"
                step="50"
                track-color="deep-purple darken-4"
                ticks="always"
                tick-size="5"
                dense
                color="deep-purple"
                thumb-label
                thumb-size="40"
                @change="handlePowerSummarizerChange"
              >
                <template v-slot:thumb-label="{ value }">
                  {{ thumbLable[Math.min(Math.floor(value / 30), 2)] }}
                </template>
              </v-slider>
            </div>
            <!-- <span class="grey--text text--darken-1 text-body-2 mt-1">
              long
            </span> -->
          </div>
        </div>
        <div v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn color="deep-purple" class="mr-2" small @click="handleTryDemo">
            <h4 class="text-capitalize white--text font-weight-black">{{ $t("message.PowerSummarizer_TryDemo") }}</h4>
            <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
          </v-btn>
        </div>
        <v-container v-else class="d-flex justify-center mt-2 mb-n3">
          <v-btn color="deep-purple" class="mr-2" small @click="handleTryDemo">
            <h4 class="text-capitalize white--text font-weight-black">{{ $t("message.PowerSummarizer_TryDemo") }}</h4>
            <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
          </v-btn>
        </v-container>
        
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PowerSummarizerHead',
  data() {
    return {
      modesTagsSelected: 0,
      modesTags: [
        {'tagName': this.$t("message.PowerSummarizer_Paragraph")},
        {'tagName': this.$t("message.PowerSummarizer_BulletPoints")},
      ],

      lengthSlider: 0,
      thumbLable: [this.$t("message.PowerSummarizer_Short"), this.$t("message.PowerSummarizer_Mid"), this.$t("message.PowerSummarizer_Long")]
    }
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      this.modesTags = [
        {'tagName': this.$t("message.PowerSummarizer_Paragraph")},
        {'tagName': this.$t("message.PowerSummarizer_BulletPoints")},
      ];
      this.thumbLable = [this.$t("message.PowerSummarizer_Short"), this.$t("message.PowerSummarizer_Mid"), this.$t("message.PowerSummarizer_Long")]
    }
  },
  mounted() {
    this.handlePowerSummarizerChange();
  },

  methods: {
    handlePowerSummarizerChange() {
      this.$nextTick(() => {
        let modesSelected = this.modesTags[this.modesTagsSelected].tagName
        let thumblabelSelected = this.thumbLable[Math.min(Math.floor(this.lengthSlider / 30),2)]
        let powerSummarizerHeadMsg = {
          'modes': "",
          'summaryLength': ""
        }
        switch(modesSelected) {
          case this.$t("message.PowerSummarizer_Paragraph"):
            powerSummarizerHeadMsg.modes = "Paragraph"
            break
          case this.$t("message.PowerSummarizer_BulletPoints"):
            powerSummarizerHeadMsg.modes = "BulletPoints"
            break
        }
        switch(thumblabelSelected) {
          case this.$t("message.PowerSummarizer_Short"):
            powerSummarizerHeadMsg.summaryLength = "Short"
            break
          case this.$t("message.PowerSummarizer_Mid"):
            powerSummarizerHeadMsg.summaryLength = "Mid"
            break
          case this.$t("message.PowerSummarizer_Long"):
            powerSummarizerHeadMsg.summaryLength = "Long"
            break
        }
        console.log(powerSummarizerHeadMsg)
        this.$store.commit('HandlepowerSummarizerHeadMsg', powerSummarizerHeadMsg);
      })
    },
    handleTryDemo() {
      this.modesTagsSelected = 0;
      this.lengthSlider = 50;
      this.handlePowerSummarizerChange()
      this.$store.commit('HandlepowerSummarizerTryDemo', true);
    }
  }

};
</script>

<style scoped>

</style>