
<template>
  <div>
    <YA_Snackbar ref="YA_SnackbarRef" />

    <v-container>
      <v-row class="justify-center">
<!--         <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-card class="cardBack" rounded="xl" outlined elevation="0">
            <v-container class="pb-6">
              <v-container class="pl-6">
                <div class="d-flex justify-center ">
                  <p class="grey--text text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black mt-2 text-center">{{ Msg0.title }}</p>
                </div>
                <div class="d-flex">
                  <p class="black--text text-h4 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black text-center">--</p>
                  <p class="grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 font-weight-black text-xl-body-1 mt-7 text-center">/month</p>
                </div>
                <div style="height: 330px;">
                  <p class="grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black my-4" v-for="(item, index) in Msg0.trueItems" :key="index">
                    <v-icon color="teal">
                      mdi-check-circle-outline
                    </v-icon>
                    {{ item.content }}
                  </p>
                  <p class="grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black my-4" v-for="(item, index) in Msg0.changedItems" :key="index">
                    <v-icon color="orange">
                      mdi-minus-circle-outline
                    </v-icon>
                    {{ item.content }}
                  </p>
                  <p class="grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black my-4" v-for="(item, index) in Msg0.faleItems" :key="index">
                    <v-icon color="red">
                      mdi-close-circle-outline
                    </v-icon>
                    {{ item.content }}
                  </p>
                </div>
              </v-container>

              <v-btn color="indigo" block x-large @click="handleNotLoginGetStarted">
                <h6 class="text-capitalize white--text text-body-1 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 font-weight-black">Get Started</h6>
                <v-icon color="white" class="mx-2">
                  mdi-arrow-right-bold
                </v-icon>
              </v-btn>
                
            </v-container>
          </v-card>
        </v-col> -->
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-card class="cardBack" rounded="xl" outlined elevation="2">
            <v-container class="pb-6">
              <v-container class="pl-6">
                <div class="d-flex justify-center ">
                  <p class="grey--text text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black mt-2 text-center">{{ Msg1.title }}</p>
                </div>
                <div class="d-flex">
                  <p class="black--text text-h4 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black text-center">${{Msg1.money}}</p>
                  <p class="grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 font-weight-black text-xl-body-1 mt-7 text-center">/{{ $t("message.Pricing_Month") }}</p>
                </div>
                <div style="height: 330px;">
                  <p class="grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black my-4" v-for="(item, index) in Msg1.trueItems" :key="'true-' + index">
                    <v-icon color="teal">
                      mdi-check-circle-outline
                    </v-icon>
                    {{ item.content }}
                  </p>
                  <p class="grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black my-4" v-for="(item, index) in Msg1.changedItems" :key="'changed-' + index">
                    <v-icon color="orange">
                      mdi-minus-circle-outline
                    </v-icon>
                    {{ item.content }}
                  </p>
                  <p class="grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black my-4" v-for="(item, index) in Msg1.faleItems" :key="'false-' + index">
                    <v-icon color="red">
                      mdi-close-circle-outline
                    </v-icon>
                    {{ item.content }}
                  </p>
                </div>
              </v-container>

              <v-btn color="indigo" block x-large @click="handleLogin">
                <h6 class="text-capitalize white--text text-body-1 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 font-weight-black">{{ $t("message.Pricing_GetStarted") }}</h6>
                <v-icon color="white" class="mx-2">
                  mdi-arrow-right-bold
                </v-icon>
              </v-btn>
                
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-card class="cardBack" rounded="xl" outlined elevation="6">
            <v-container class="pb-6">
              <v-container class="pl-6">
                <div class="d-flex justify-center ">
                  <p class="amber--text text--darken-3 text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black mt-2 text-center">{{ Msg2.title }}</p>
                </div>

                <div class="d-flex">
                  <p class="grey--text text--darken-2 text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black text-center mt-5 mr-1 text-decoration-line-through">${{Msg2.realmoney}}</p>
                  <p class="black--text text-h4 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black text-center">${{Msg2.money}}</p>
                  <p class="grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 font-weight-black text-xl-body-1 mt-7 text-center">/{{ $t("message.Pricing_Month") }}</p>
                </div>
                <div style="height: 330px;">
                  <p class="grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black my-4" v-for="(item, index) in Msg2.items" :key="index">
                    <v-icon color="teal">
                      mdi-check-circle-outline
                    </v-icon>
                    {{ item.content }}
                  </p>
                </div>
              </v-container>

                <v-btn color="indigo" block x-large @click="handleSubscribe" :loading="handleSubscribeLoading">
                  <h6 class="text-capitalize white--text text-body-1 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 font-weight-black">{{ $t("message.Pricing_SubscribeNow") }}</h6>
                  <v-icon color="white" class="mx-2">
                    mdi-arrow-right-bold
                  </v-icon>
                </v-btn>
              
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { requestPost } from '@/utils/SystemUtils/request.js';
import YA_Snackbar from '@/components/WebBar/YA_Snackbar.vue';



export default {
  name: 'YA_HomePricing',
  components: {
    YA_Snackbar,
  },
  data() {
    return {
      Msg0: {
        'title': this.$t("message.Pricing_NotLoggedIn"),
        'money': "0",
        'trueItems': [
        ],
        'changedItems': [
          {'content':this.$t("message.Pricing_AccessToPartOfAITools")},
          {'content':this.$t("message.Pricing_20FreeMessagesAtOnce")},
          {'content':this.$t("message.Pricing_StandardAIModels")},
        ],
        'faleItems': [
          {'content':this.$t("message.Pricing_FasterResponse")},
          {'content':this.$t("message.Pricing_EarlyAccessToNewFeatures")},
          {'content':this.$t("message.Pricing_PriorityCustomerServiceSupport")},
        ],
      },
      Msg1: {
        'title': this.$t("message.Pricing_Free"),
        'money': "0",
        'trueItems': [
          {'content':this.$t("message.Pricing_AccessToAllAITools")},
        ],
        'changedItems': [
          {'content':this.$t("message.Pricing_5FreeMessagesPerDay")},
          {'content':this.$t("message.Pricing_StandardAIModels")},
        ],
        'faleItems': [
          {'content':this.$t("message.Pricing_FasterResponse")},
          {'content':this.$t("message.Pricing_EarlyAccessToNewFeatures")},
          {'content':this.$t("message.Pricing_PriorityCustomerServiceSupport")},
        ],
      },
      Msg2: {
        'title': this.$t("message.Pricing_Pro"),
        'money': "15",
        'realmoney': "20",
        'items': [
          {'content':this.$t("message.Pricing_AccessToAllAITools")},
          {'content':this.$t("message.Pricing_NoDailyQuestionsLimit")},
          {'content':this.$t("message.Pricing_AdvancedAIModels")},
          {'content':this.$t("message.Pricing_FasterResponse")},
          {'content':this.$t("message.Pricing_EarlyAccessToNewFeatures")},
          {'content':this.$t("message.Pricing_PriorityCustomerServiceSupport")},
          {'content':this.$t("message.Pricing_3DayMoneyBackGuarantee")},
        ],
      },
      isLogin: false,
      userID: '',
      token: '',
      handleSubscribeLoading: false,
      IsProUser: false,
    }
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      this.Msg0 = {
        'title': this.$t("message.Pricing_NotLoggedIn"),
        'money': "0",
        'trueItems': [
        ],
        'changedItems': [
          {'content':this.$t("message.Pricing_AccessToPartOfAITools")},
          {'content':this.$t("message.Pricing_20FreeMessagesAtOnce")},
          {'content':this.$t("message.Pricing_StandardAIModels")},
        ],
        'faleItems': [
          {'content':this.$t("message.Pricing_FasterResponse")},
          {'content':this.$t("message.Pricing_EarlyAccessToNewFeatures")},
          {'content':this.$t("message.Pricing_PriorityCustomerServiceSupport")},
        ],
      }
      this.Msg1 = {
        'title': this.$t("message.Pricing_Free"),
        'money': "0",
        'trueItems': [
          {'content':this.$t("message.Pricing_AccessToAllAITools")},
        ],
        'changedItems': [
          {'content':this.$t("message.Pricing_5FreeMessagesPerDay")},
          {'content':this.$t("message.Pricing_StandardAIModels")},
        ],
        'faleItems': [
          {'content':this.$t("message.Pricing_FasterResponse")},
          {'content':this.$t("message.Pricing_EarlyAccessToNewFeatures")},
          {'content':this.$t("message.Pricing_PriorityCustomerServiceSupport")},
        ],
      }
      this.Msg2 = {
        'title': this.$t("message.Pricing_Pro"),
        'money': "15",
        'realmoney': "20",
        'items': [
          {'content':this.$t("message.Pricing_AccessToAllAITools")},
          {'content':this.$t("message.Pricing_NoDailyQuestionsLimit")},
          {'content':this.$t("message.Pricing_AdvancedAIModels")},
          {'content':this.$t("message.Pricing_FasterResponse")},
          {'content':this.$t("message.Pricing_EarlyAccessToNewFeatures")},
          {'content':this.$t("message.Pricing_PriorityCustomerServiceSupport")},
          {'content':this.$t("message.Pricing_3DayMoneyBackGuarantee")},
        ],
      }
    }
  },
  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
        if (this.isLogin) {
          this.RobotLinksLoginLoading = false;
          this.ButtonsLoginLoading = false;
          
        } else {
          this.RobotLinksLoginLoading = true;
          this.ButtonsLoginLoading = true;
        }
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

    this.$store.watch(
      (state) => state.userMsg.IsProUser, () => {
        this.IsProUser = this.$store.getters.getIsProUser;
      }, {
        deep: true
      }
    );
    this.IsProUser = this.$store.getters.getIsProUser;
  },

  mounted() {

  },

  methods: {
    handleNotLoginGetStarted() {
      let url='';
      url = '/';
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    },
    handleLogin() {
      let url='';
      if (!this.isLogin) {
        url = '/Login';
      } else {
        url = '/chat';
      }
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    },
    async handleSubscribe() {
      // console.log("Oh My God, You are so kind.")
      this.handleSubscribeLoading = true;

      if (this.IsProUser) {
        this.$refs.YA_SnackbarRef.handleSnackbar('You have already subscribed to PromptBoom 😉');
        this.handleSubscribeLoading = false;

      } else {
        if (this.isLogin) {
          let request_header = {
            email: this.userID,
            token: this.token
          };
          let request_json = {};

          let request_url = '/requestSubscribe';
          const res = await requestPost(request_header, request_json, request_url);

          if (res.statusCode == 1) {
            window.location.href = res.data.sessionURL
            this.handleSubscribeLoading = false;
          } else {
            this.$refs.YA_SnackbarRef.handleSnackbar('Failed' + res.statusInfo);
            this.handleSubscribeLoading = false;
          }
          
        } else {
          this.$refs.YA_SnackbarRef.handleSnackbar('Please Login');
          this.handleSubscribeLoading = false;

        }
      }
    }
  }

};
</script>

<style scoped>

</style>