<template>
  <div>
    <YA_Snackbar ref="YA_SnackbarRef" />
    
    <YA_ScrollComponent ref="ScrollComRef" :scrollHeight="scrollHeight" @scroll="onScroll">
      <div class="mb-4">
        <v-container>
          <p class="blue-grey--text text--darken-4 text-center text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black my-4">{{ RobotName }}</p>

          <!-- <div class="d-flex justify-center align-center mb-4" v-if="RobotName == 'PowerChat'">
            <v-btn class="d-flex justify-start" large rounded @click="handleToTelegram">
              <v-avatar size="50">
                <v-img
                  height="20"
                  alt="Avatar"
                  contain
                  src="@/assets/telegramLogo.png"
                ></v-img>
              </v-avatar>
              
              <span class="text-capitalize font-weight-black">PowerChat on Telegram</span>
              <v-icon color="red darken-4" size="26px" class="mt-n2">mdi-new-box</v-icon>
            </v-btn>
          </div> -->
          <p class="blue-grey--text text-center mb-8">{{ RobotDescription }}</p>

          <!-- <YA_webEmpty class="mt-16" v-if="!isLogin" /> -->

          <div>
            <v-row class="justify-center" v-for="(item, index) in chatContent[RobotID]" :key="index" :ref="'row' + index">
              <v-col cols="12" sm="12" md="10" lg="10" xl="10">
                <v-card elevation="2" class='pt-1'>
                  <div class="grey lighten-4">
                    <v-icon color='teal darken-3' class='ml-5 my-2'>mdi-account</v-icon>
                    <div class="px-5 py-5" style="white-space: pre-line;">{{item.question}}</div>
                  </div>
                  <v-divider></v-divider>
                  <div>
                    <div>
                      <v-icon color='teal darken-3' class='ml-5 my-2'>mdi-robot</v-icon>
                      <v-icon v-show="item.isDone" class='float-right mr-5 my-2' color='teal' @click="copyAnswer(item.answer)">mdi-content-copy</v-icon>
                      <v-icon v-show="item.isDone" class='float-right mr-7 my-2' color='teal' @click="shareAnswer('row' + index)">mdi-image-move</v-icon>
                    </div>
                    <v-progress-linear color="teal accent-4" indeterminate rounded height="6" :active="!item.isDone"></v-progress-linear>

                    <div v-html="htmlfy(item.answer)" class="px-5 py-2" style="white-space: pre-line;"></div>
                    <v-divider></v-divider>
                    <div v-html="item.note" class="px-5 py-5 text-center" style="white-space: pre-line;"></div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
    </YA_ScrollComponent>


    <v-footer color="white">
      <v-row class="justify-center">
        <v-col cols="12" sm="12" md="10" lg="10" xl="10">
          <v-textarea 
            ref='textareaQuestion' 
            @keypress.enter.exact="handlePowerChat"
            :label="$t('message.PowerChat_TalkInput')"
            auto-grow
            color="teal"
            rows="1"
            v-model='question'
            :loading='loading'
            outlined
            :disabled="isRunning"
            prepend-inner-icon='mdi-magnify'
          >
            <template v-slot:prepend>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mt-n1" color="secondary" fab x-small v-bind="attrs" v-on="on" @click="handlePowerChatClear">
                    <v-icon>mdi-brush-variant</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("message.PowerChat_Clear") }}</span>
              </v-tooltip>
            </template>

            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-show='!isRunning' class="mt-n1" color="teal" fab x-small v-bind="attrs" v-on="on" @click="handlePowerChat">
                    <v-icon color="white">mdi-send</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("message.PowerChat_Send") }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-show='isRunning' class="error mt-n1" fab x-small v-bind="attrs" v-on="on" @click="handlePowerChatStop">
                    <v-icon>mdi-stop</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("message.PowerChat_Stop") }}</span>
              </v-tooltip>
            </template>
          </v-textarea>
        </v-col>
      </v-row>
    </v-footer>

    <!-- </v-card> -->
  </div>
</template>

<script>
import YA_webEmpty from '@/components/WebBar/YA_webEmpty.vue';
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';

import YA_Snackbar from '@/components/WebBar/YA_Snackbar.vue';
import { getDid } from '@/utils/SystemUtils/fingerprint.js'
import { getToken } from '@/utils/SystemUtils/token.js'
import { getBaseURL } from '@/utils/SystemUtils/url.js'
import { getCookie, setCookie, removeCookie } from '@/utils/SystemUtils/cookies.js'
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { setNotLoginLimit } from '@/utils/SystemUtils/userNotLogin.js'
import { createHash } from 'crypto-browserify';

import {marked} from 'marked';
import { mangle } from "marked-mangle";
import { gfmHeadingId } from "marked-gfm-heading-id";
import markedLinkifyIt from "marked-linkify-it";
import markedKatex from "marked-katex-extension";
import 'katex/dist/katex.min.css';

import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css';

import DOMPurify from 'dompurify';

import html2canvas from 'html2canvas';

export default {
  name: 'PowerChatTalk',
  components: {
    YA_Snackbar,
    YA_ScrollComponent,
    // YA_webEmpty,
  },
  data() {
    return {
      chatContent: {},

      question: '',
      loading: false,
      isRunning:false,
      enableScroll:true,

      title: 'PowerChat',
      RobotID: '',
      RobotName: '',
      RobotDescription: '',

      isLogin: false,
      userID: '',
      token: '',

      ChatUseNum: 0,
      NotLoginChatNum: 0,
    }
  },
  computed: {
    scrollHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 190;
      } else {
        return 130;
      }
    }
  },
  created() {
    this.$store.watch(
      (state) => state.powerChatMsg.PowerChatRobotLinks, () => {
        this.chatContent = this.mergeKeys(this.$store.getters.getPowerChatRobotLinks, this.chatContent);
      }, {
        deep: true
      }
    );
    this.chatContent = this.mergeKeys(this.$store.getters.getPowerChatRobotLinks, this.chatContent);

    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

    this.$store.watch(
      (state) => state.powerChatMsg.RobotID, () => {
        this.RobotID = this.$store.getters.getRobotID;
        this.$nextTick(() => {
          this.scrollDown();
        })
      }, {
        deep: true
      }
    );
    this.RobotID = this.$store.getters.getRobotID;

    this.$store.watch(
      (state) => state.powerChatMsg.RobotName, () => {
        this.RobotName = this.$store.getters.getRobotName;
        this.RobotDescription = this.$store.getters.getRobotDescription;
      }, {
        deep: true
      }
    );
    this.RobotName = this.$store.getters.getRobotName;
    this.RobotDescription = this.$store.getters.getRobotDescription;

    this.$store.watch(
      (state) => state.userMsg.ChatUseNum, () => {
        this.ChatUseNum = this.$store.getters.getChatUseNum;
      }, {
        deep: true
      }
    );
    this.ChatUseNum = this.$store.getters.getChatUseNum;
    
    this.$store.watch(
      (state) => state.userMsg.NotLoginChatNum, () => {
        this.NotLoginChatNum = this.$store.getters.getNotLoginChatNum;
      }, {
        deep: true
      }
    );
    this.NotLoginChatNum = this.$store.getters.getNotLoginChatNum;
  },
  beforeDestroy() {
    window.removeEventListener('wheel', this.wheelEventHandler);

  },
  mounted() {
    let did = getDid().then(res => {
      setCookie('did', res)
      setLocalStorage('did', res)
    });
    let lastSession=Date.now()
    setCookie('lastSession', lastSession)

    // for (let key in this.chatContent) {
    //   removeLocalStorage(key + 'chatlist')
    // }
    const localstoragekeys = Object.keys(localStorage);

    localstoragekeys.forEach(function(key) {
      if (key.endsWith("chatlist")) {
        removeLocalStorage(key);
      }
    });

    marked.use(mangle());
    marked.use(gfmHeadingId({prefix: "my-prefix-"}));
    marked.use(markedLinkifyIt());
    marked.use(markedKatex({throwOnError: false}));

    this.wheelEventHandler = this.disableScroll.bind(this);
    window.addEventListener('wheel', this.wheelEventHandler);

  },
  methods: {
    utoa(data) {
      return btoa(unescape(encodeURIComponent(data)));
    },
    atou(b64) {
      return decodeURIComponent(escape(atob(b64)));
    },
    htmlfy(answer) {
      // console.log(DOMPurify.sanitize(marked.parse(answer)))
      return DOMPurify.sanitize(marked.parse(answer));      
    },
    mergeKeys(obj1, obj2) {
      const result = { ...obj2 }; 
      for (const key in obj1) {
          if (Object.prototype.hasOwnProperty.call(obj1, key)) {
              if (!Object.prototype.hasOwnProperty.call(result, key)) {
                  result[key] = obj1[key];
              }
          }
      }

      return result;
    },
    highlightCode() {
      this.$nextTick(() => {
        // const codeBlocks = this.$el.querySelectorAll('pre code');
        const codeBlocks = this.$el.querySelectorAll('pre code, :not(pre) > code');
        codeBlocks.forEach((block) => {
          const isInline = block.parentNode.tagName !== 'PRE';
          if (!isInline) {
            let copyBtn = block.previousSibling;
            if (!copyBtn || copyBtn.tagName !== 'BUTTON') {
              copyBtn = document.createElement('button');
              copyBtn.innerHTML = 'Copy code';
              copyBtn.style.backgroundColor = 'grey';
              copyBtn.style.color = '#fff';
              copyBtn.style.padding = '10px';
              copyBtn.style.margin = '10px';
              copyBtn.style.position = 'absolute';
              copyBtn.style.top = '0';
              copyBtn.style.right = '0';
              block.parentNode.style.position='relative'
              block.parentNode.insertBefore(copyBtn, block);
              // Add click event listener to copy button
              copyBtn.addEventListener('click', function() {
                navigator.clipboard.writeText(block.innerText)
                // let range = document.createRange();
                // range.selectNode(block);
                // window.getSelection().addRange(range);
                // document.execCommand('copy');
                // window.getSelection().removeAllRanges();
                copyBtn.innerHTML='Copied!'
                setTimeout(function(){
                  copyBtn.innerHTML='Copy code'
                },2000)
              });
            }
          }
          // deprecated
          // hljs.highlightBlock(block);
          hljs.highlightElement(block);
        });
      });
    },
    onScroll (e) {
      // this.pageSetNum = e.target.scrollTop / this.pageHeight;

      // console.log(e.target.scrollTop)
      // console.log(this.pageHeight)
      // console.log(this.pageSetNum)

      // this.offsetTop = e.target.scrollTop
      this.disableScroll();
    },
    disableScroll() {
      this.enableScroll = false;
    },
    scrollDown() {
      this.$refs.ScrollComRef.comScrollDown();
      // window.scrollTo(0, document.body.scrollHeight);
    },
    scrollScreen() {
      this.$nextTick(() => {
        if(this.enableScroll){
          this.$refs.ScrollComRef.scrollScreen();
        }  
      })
    },
    focus(){
      // focus text-area
      this.$nextTick(() => {
        // console.log(this.$refs.textareaQuestion)
        this.$refs.textareaQuestion.focus();
      })
    },
    copyAnswer(answer){
      answer=this.htmlfy(answer)

      // this will only copy the html source code
      // navigator.clipboard.writeText(answer)

      const temp = document.createElement('div');
      // temp.innerHTML = answer.replace(/\n/g, '<br>');
      temp.innerHTML = answer;
      document.body.appendChild(temp);
      const range = document.createRange();
      range.selectNode(temp);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      document.body.removeChild(temp);

      this.$refs.YA_SnackbarRef.handleSnackbar('Copied');

    },
    shareAnswer(refName){
      let element = this.$refs[refName][0]; 
      html2canvas(element).then(canvas => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL(); 
        link.download = 'PowerChat.png';
        link.click();
      });
    },

    cacheChatList(question, answer, robot) {
      let tempOneQuestion = {
        'role': 'user',
        'content': question
      }
      let tempOneAnswer = {
        'role': 'assistant',
        'content': answer
      }

      let localChatList = []

      if (getLocalStorage(robot + 'chatlist')) {
        localChatList = JSON.parse(getLocalStorage(robot + 'chatlist'));
      }
      localChatList.push(tempOneQuestion)
      localChatList.push(tempOneAnswer)
      if (localChatList.length > 20) {
        localChatList.shift()
        localChatList.shift()
      }
      setLocalStorage(robot + 'chatlist', JSON.stringify(localChatList))
    },
    try_error(error, robot) {

      this.chatContent[robot][this.chatContent[robot].length - 1]['answer'] += "<strong style='color:red'>Network error[2001],you can try again or notify us by sending an email to <a href='mailto:hello@promptboom.com'>hello@promptboom.com</a>. Thank you</strong>";
    },
    try_finally(robot, response) {
      if (response.status === 200) {
        this.$store.commit('HandleChatUseNum', this.ChatUseNum - 1);
        this.$store.commit('HandleNotLoginChatNum', this.NotLoginChatNum - 1);
        setNotLoginLimit();
      }
      
      this.isRunning = false;
      this.loading = false;
      this.chatContent[robot][this.chatContent[robot].length - 1]['isDone'] = true;

      let tempAnswer = this.chatContent[robot][this.chatContent[robot].length - 1]['answer'];
      if (tempAnswer.length == 0) {
        this.chatContent[robot][this.chatContent[robot].length - 1]['answer'] = "<strong style='color:red'>Network error[2001],you can try again or notify us by sending an email to <a href='mailto:hello@promptboom.com'>hello@promptboom.com</a>. Thank you</strong>";
      }
      this.focus()
    },

    async handlePowerChat() {
      if (this.isLogin) {
        if (this.ChatUseNum > 0) {
          const robot = this.RobotID
          event.preventDefault();
          let question = this.question.trim();
          // if (question.length > 10000) {
          if (question.length > 60000) {
            this.$refs.YA_SnackbarRef.handleSnackbar('The length of your question is too long');
          } else {
            if (question.length > 0) {
              this.enableScroll=true
              let onechat = {
                'question': this.question,
                'answer': ``,
                'isDone': false,
                'note':''
              }
              this.chatContent[robot].push(onechat);

              this.scrollScreen();
              this.question = '';
              this.isRunning = true;
              this.loading = true;

              let response;
              try {
                response = await this.requestPowerChat(question)

              } catch (error) {
                // console.log(error)
                this.try_error(error, robot)
              } finally {
                this.try_finally(robot, response)
              }
            }
          }
        } else {
          this.$refs.YA_SnackbarRef.handleSnackbar('You have 0 free message left today');
        }
      } else if (this.NotLoginChatNum > 0){
        const robot = this.RobotID
        event.preventDefault();
        let question = this.question.trim();
        // if (question.length > 10000) {
        if (question.length > 60000) {
          this.$refs.YA_SnackbarRef.handleSnackbar('The length of your question is too long');
        } else {
          if (question.length > 0) {
            this.enableScroll=true
            let onechat = {
              'question': this.question,
              'answer': ``,
              'isDone': false,
              'note':''
            }
            this.chatContent[robot].push(onechat);

            this.scrollScreen();
            this.question = '';
            this.isRunning = true;
            this.loading = true;

            let response;
            try {
              response = await this.requestPowerChat(question)

            } catch (error) {
              // console.log(error)
              this.try_error(error, robot)
            } finally {
              this.try_finally(robot, response)
            }
          }
        }
      } else {
        this.$store.commit('HandlePromptBoomSnackBar', 'Please Login')
        // if (this.$route.path !== '/Login') {
        //   this.$router.push({ path: '/Login'});
        // }
      }
    },

    async requestPowerChat(question) {
      this.$refs.ScrollComRef.setMouseScroll(false);  // 将 isMouseScroll 设置为 false
      const robot = this.RobotID
      let chatList = []

      // check sessionTime
      let lastSession=getCookie('lastSession')
      // console.log(lastSession)
      let currentSession=Date.now()
      // console.log(currentSession)
      // console.log(currentSession-lastSession)
      if((currentSession-lastSession)>1000*60*20){
        setCookie('lastSession', currentSession)
        removeLocalStorage(this.RobotID + 'chatlist')
      }

      if (getLocalStorage(this.RobotID + 'chatlist')) {
        chatList = JSON.parse(getLocalStorage(this.RobotID + 'chatlist'))
      }

      chatList.push({
        "role": "user",
        "content": question
      })

      const t = Date.now()
      const token=getToken()
      const r = t + ":" + "question:" + token
      const sign = createHash('sha256').update(r).digest('hex')

      let request_json = {
        'did': getCookie('did'),
        'chatList': chatList,
        'botID': this.RobotID,
        'special': {
          'referer':document.referrer||'no-referer',
          'path':location.href
        }
      };

      let raw_requst_json = {
        'data': this.utoa(JSON.stringify(request_json))
      };

      // let rootDomain = location.hostname.split('.').slice(-2).join('.');

      let baseURL=getBaseURL()
      let requestUrl =`${baseURL}/requestPowerChat`

      const response = await fetch(requestUrl, {
        method: 'POST',
        headers:{
          email: this.userID,
          token: this.token,
          isProUser: this.$store.getters.getIsProUser
        },
        body: JSON.stringify(raw_requst_json)
      });
      // console.log('powerchat', response)
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8")
      while (this.isRunning) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        let decodedValue = decoder.decode(value);
        this.chatContent[robot][this.chatContent[robot].length - 1]['answer']+=decodedValue;
        this.highlightCode()
        this.scrollScreen()
      }
      if (this.chatContent[robot][this.chatContent[robot].length - 1]['answer'].length > 0) {
        if (response.status === 200) {
          this.cacheChatList(question, this.chatContent[robot][this.chatContent[robot].length - 1]['answer'], robot);
        }
        this.chatContent[robot][this.chatContent[robot].length - 1]['note']=`==<strong style='color:blue;text-align:center'>Powered by <a href='https://promptboom.com'>PromptBoom</a></strong>==`
      }
      return response
    },

    handlePowerChatStop(){
      this.isRunning=false;
      this.loading = false;
      
    },
    handlePowerChatClear(){
      const robot = this.RobotID

      removeLocalStorage(robot + 'chatlist')
      
      this.chatContent[robot] = [];

    },
    handleToTelegram() {
      if (this.$route.path !== '/EveryAPP') {
        this.$router.push({ path: '/EveryAPP'});
      }
    },
  }

};
</script>

<style scoped>
/* .bottom-fixed {
  position: fixed;
  bottom: 0;
  background-color: pink;
} */
</style>