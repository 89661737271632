<template>
  <div>
    <YA_Snackbar ref="YA_SnackbarRef" />

    <v-container>
      <v-container class="d-flex justify-center">
        <p class="blue-grey--text text--darken-4 text-center text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black mt-8">{{ $t("message.PowerTelegram_Title") }} 🛸</p>
      </v-container>
      <p class="text-center text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1">{{ $t("message.PowerTelegram_Description") }}</p>
      <v-row class="justify-center mt-16">
        <v-col cols="10" sm="10" md="3" lg="3" xl="3">
          <v-img 
            src="@/assets/pic_telegrampowerchat.png" 
            contain
            rounded
          >
          </v-img>
        </v-col>
      </v-row>
      <v-container class="d-flex justify-center">
        <v-btn color="rgb(41,169,235)" x-large class="mt-16" rounded @click="handleTelegram" :loading="handleTelegramLoading">
          <v-icon right color="white" class="mx-auto mr-4">
            mdi-human-greeting-variant
          </v-icon>
          <h6 class="text-capitalize white--text text-body-1 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 font-weight-black">              
            {{ $t("message.PowerTelegram_Button") }}
          </h6>
        </v-btn>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import { requestPost } from '@/utils/SystemUtils/request.js';
import YA_Snackbar from '@/components/WebBar/YA_Snackbar.vue';

export default {
  name: 'YA_TelegramContent1',
  components: {
    YA_Snackbar,
  },
  data() {
    return {
      isLogin: false,
      userID: '',
      token: '',
      handleTelegramLoading: false,
      IsProUser: false,
    }
  },
  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
        if (this.isLogin) {
          this.RobotLinksLoginLoading = false;
          this.ButtonsLoginLoading = false;
          
        } else {
          this.RobotLinksLoginLoading = true;
          this.ButtonsLoginLoading = true;
        }
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

    this.$store.watch(
      (state) => state.userMsg.IsProUser, () => {
        this.IsProUser = this.$store.getters.getIsProUser;
      }, {
        deep: true
      }
    );
    this.IsProUser = this.$store.getters.getIsProUser;
  },
  mounted() {

  },

  methods: {
    handleLogin() {
      let url='';
      if (!this.isLogin) {
        url = '/Login';
      } else {
        url = '/chat';
      }
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    },
    async handleTelegram() {
      // console.log("Oh My God, You are so kind.")
      this.handleTelegramLoading = true;

      if (this.isLogin) {
        if (this.IsProUser) {
          let request_header = {
            email: this.userID,
            token: this.token
          };
          let request_json = {};

          let request_url = '/requestGetTelegram';
          const res = await requestPost(request_header, request_json, request_url);

          if (res.statusCode == 1) {
            window.location.href = res.data
            // window.open(res.data, '_blank');
            this.handleTelegramLoading = false;
          } else {
            this.$refs.YA_SnackbarRef.handleSnackbar('Failed' + res.statusInfo);
            this.handleTelegramLoading = false;
          }
          
        } else {
          this.$refs.YA_SnackbarRef.handleSnackbar('Subscribe to PowerChat to Try PowerChat on Telegram. 😉');
          this.handleTelegramLoading = false;

        }
        

      } else {
        this.$refs.YA_SnackbarRef.handleSnackbar('Please Login');
        this.handleTelegramLoading = false;
        this.handleLogin();
      }
    }
  }

};
</script>

<style scoped>
</style>