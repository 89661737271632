<template>
  <div>
    <v-container>
      <v-textarea
        class="mt-2 mb-n7"
        filled
        v-model="powerGrammarFixRes"
        no-resize
        height="400"
        counter
        hide-details="auto"
        color="green"
      ></v-textarea>
    </v-container>
    <v-container class="d-flex mt-6 justify-end">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="green darken-1" v-bind="attrs" v-on="on" class="mx-1 mt-n2" icon @click="handleCopy">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("message.PowerGrammarFix_Copy") }}</span>
      </v-tooltip>
    </v-container>
  </div>
</template>

<script>
import {marked} from 'marked';
import DOMPurify from 'dompurify';
export default {
  name: 'PowerGrammarFixRight',
  data() {
    return {
      powerGrammarFixRes: '',
    }
  },
  computed: {
  },
  created() {
    this.$store.watch(
      (state) => state.powerGrammarFixMsg.powerGrammarFixRes, () => {
        this.powerGrammarFixRes = this.$store.getters.getpowerGrammarFixRes;
      }, {
        deep: true
      }
    );
    this.powerGrammarFixRes = this.$store.getters.getpowerGrammarFixRes;
  },
  mounted() {

  },

  methods: {
    htmlfy(answer) {
      // console.log(DOMPurify.sanitize(marked.parse(answer)))
      return DOMPurify.sanitize(marked.parse(answer));      
    },
    handleCopy() {
      const anwser=this.htmlfy(this.powerGrammarFixRes)
      const temp = document.createElement('div');
      temp.innerHTML = anwser;
      document.body.appendChild(temp);
      const range = document.createRange();
      range.selectNode(temp);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      document.body.removeChild(temp);
      this.$store.commit('HandlePromptBoomSnackBar', 'Copied')
    }
  }

};
</script>

<style scoped>
</style>