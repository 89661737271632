
<template>
  <v-container class="d-flex justify-center mt-10 flex-wrap">
    <v-img src="@/assets/pic_email.png" height="300" contain sm="12" md="8" lg="8" xl="8"></v-img>
    <v-container class="d-flex justify-center">
      <p class="blue-grey--text text--darken-4 text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">{{ $t("message.Contact_Title") }}</p>
    </v-container>
    <!-- <p class="text-center text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1">Our team is dedicated to providing the best service experience possible and we are always happy to assist you. 😘</p> -->

    <v-container class="d-flex justify-center align-center flex-wrap" style="width: 1080px;">
      <p class="text-center mt-2 text-body-2 text-sm-body-1 text-md-h6 text-lg-h6 text-xl-h6 font-weight-black">
        {{ $t("message.Contact_Content") }}
      </p>
      <!-- <p class="mt-5 text-h5">Our team is dedicated to providing the best service experience possible and we are always happy to assist you. Whether you need help using our services or have general inquiries, we are here to help!</p> -->
      <div class="email mt-4"> <a class="text-h6" href="mailto:hello@promptboom.com">hello@promptboom.com</a> </div>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'YA_HomeContact',
  data() {
    return {

    }
  },
  mounted() {

  },

  methods: {

  }

};
</script>

<style scoped>

</style>