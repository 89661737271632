<template>
  <div>
    <v-card elevation="0" rounded="lg">
      <v-container class="d-flex flex-wrap justify-space-between">
        <div class="d-flex flex-wrap justify-center">
          <span class="yellow--text text--darken-3 text-body-1 font-weight-bold mt-1">
            <v-icon color="yellow darken-4" class="mt-n1">mdi-collage</v-icon>
            {{ $t("message.PowerWriter_Language") }}:
          </span>
          <div class="ml-xl-6 ml-lg-6 ml-md-3 mb-n3">
            <v-tabs v-if="!refresh" v-model="modesTagsSelected" color="yellow darken-4" height="38">
              <v-tab v-for="(item, index) in modesTags" :key="index" @change="handlePowerWriterChange">
                <span class="text-capitalize font-weight-bold text-body-1 mb-1">{{ item }}</span>
              </v-tab>
              <v-menu
                v-if="moreTags.length"
                offset-y
                max-height="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="align-self-center mr-4"
                    color="yellow darken-4"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="item in moreTags"
                    :key="item"
                    @click="handleMoreTagChoice(item)"
                  >
                    {{ item }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-tabs>
          </div>
          <!-- <div style="width: 200px;" class="ml-4 mt-n1">
            <v-select
              color="yellow darken-4"
              class="font-weight-bold"
              v-model="modesTagsSelected"
              :menu-props="{ maxHeight: '300', offsetY: true }"
              :items="modesTags"
              label="Choose Language"
              @change="handlePowerWriterChange"
              dense
              outlined
            ></v-select>
          </div> -->
        </div>
        <div v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn color="yellow darken-4" class="mr-2" small @click="handleTryDemo">
            <h4 class="text-capitalize white--text font-weight-black">{{ $t("message.PowerWriter_TryDemo") }}</h4>
            <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
          </v-btn>
        </div>
        <v-container v-else class="d-flex justify-center mt-4 mb-n3">
          <v-btn color="yellow darken-4" class="mr-2" small @click="handleTryDemo">
            <h4 class="text-capitalize white--text font-weight-black">{{ $t("message.PowerWriter_TryDemo") }}</h4>
            <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
          </v-btn>
        </v-container>
        
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PowerWriterHead',
  data() {
    return {
      modesTagsSelected: 0,

      refresh: false,
    }
  },
  mounted() {
    this.handlePowerWriterChange();
  },

  computed: {
    modesTags() {
      return [this.$t("message.PowerWriter_Auto"), this.$t("message.PowerWriter_English")];
    },
    moreTags() {
      return [this.$t("message.PowerWriter_Arabic"), this.$t("message.PowerWriter_Cantonese"), this.$t("message.PowerWriter_Chinese"), this.$t("message.PowerWriter_Filipino"), this.$t("message.PowerWriter_French"), this.$t("message.PowerWriter_German"), this.$t("message.PowerWriter_Japanese"), this.$t("message.PowerWriter_Hindi"), this.$t("message.PowerWriter_Indonesian"), this.$t("message.PowerWriter_Italian"), this.$t("message.PowerWriter_Korean"), this.$t("message.PowerWriter_Malay"), this.$t("message.PowerWriter_Portuguese"), this.$t("message.PowerWriter_Russian"), this.$t("message.PowerWriter_Spanish"), this.$t("message.PowerWriter_Thai"), this.$t("message.PowerWriter_Vietnamese")]
    }
  },

  methods: {
    handleMoreTagChoice(item) {
      this.refresh = true;
      if (this.modesTags.length >= 3) {
        this.modesTags[2] = item;
      } else {
        this.modesTags.push(item)
      }
      this.modesTagsSelected = 2;
      this.handlePowerWriterChange();
      this.$nextTick(() => {
        this.refresh = false;
      })
    },
    handlePowerWriterChange() {
      this.$nextTick(() => {
        let languageSelected = this.modesTags[this.modesTagsSelected]
        let powerWriterHeadMsg;
        switch (languageSelected) {
          case this.$t('message.PowerWriter_Auto'):
            powerWriterHeadMsg = {'Language': 'Auto'}
            break
          case this.$t('message.PowerWriter_English'):
            powerWriterHeadMsg = {'Language': 'English'}
            break
          case this.$t('message.PowerWriter_Arabic'):
            powerWriterHeadMsg = {'Language': 'Arabic'}
            break
          case this.$t('message.PowerWriter_Cantonese'):
            powerWriterHeadMsg = {'Language': 'Cantonese'}
            break
          case this.$t('message.PowerWriter_Chinese'):
            powerWriterHeadMsg = {'Language': 'Chinese'}
            break
          case this.$t('message.PowerWriter_Filipino'):
            powerWriterHeadMsg = {'Language': 'Filipino'}
            break
          case this.$t('message.PowerWriter_French'):
            powerWriterHeadMsg = {'Language': 'French'}
            break
          case this.$t('message.PowerWriter_German'):
            powerWriterHeadMsg = {'Language': 'German'}
            break
          case this.$t('message.PowerWriter_Japanese'):
            powerWriterHeadMsg = {'Language': 'Japanese'}
            break
          case this.$t('message.PowerWriter_Hindi'):
            powerWriterHeadMsg = {'Language': 'Hindi'}
            break
          case this.$t('message.PowerWriter_Indonesian'):
            powerWriterHeadMsg = {'Language': 'Indonesian'}
            break
          case this.$t('message.PowerWriter_Italian'):
            powerWriterHeadMsg = {'Language': 'Italian'}
            break
          case this.$t('message.PowerWriter_Korean'):
            powerWriterHeadMsg = {'Language': 'Korean'}
            break
          case this.$t('message.PowerWriter_Malay'):
            powerWriterHeadMsg = {'Language': 'Malay'}
            break
          case this.$t('message.PowerWriter_Portuguese'):
            powerWriterHeadMsg = {'Language': 'Portuguese'}
            break
          case this.$t('message.PowerWriter_Russian'):
            powerWriterHeadMsg = {'Language': 'Russian'}
            break
          case this.$t('message.PowerWriter_Spanish'):
            powerWriterHeadMsg = {'Language': 'Spanish'}
            break
          case this.$t('message.PowerWriter_Thai'):
            powerWriterHeadMsg = {'Language': 'Thai'}
            break
          case this.$t('message.PowerWriter_Vietnamese'):
            powerWriterHeadMsg = {'Language': 'Vietnamese'}
            break
        }
        this.$store.commit('HandlepowerWriterHeadMsg', powerWriterHeadMsg);
      })
    },
    handleTryDemo() {
      this.modesTagsSelected = 0;
      this.handlePowerWriterChange();
      this.$nextTick(() => {
        this.$store.commit('HandlepowerWriterTryDemo', true);
      })
    }
  }

};
</script>

<style scoped>

</style>