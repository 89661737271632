<template>
  <div>
    <v-navigation-drawer 
      width="200"
      app
      clipped 
      v-model="leftVmodel"
      :expand-on-hover="!leftBarTag && !$vuetify.breakpoint.smAndDown"
      color="rgb(245,245,245)"
      :mini-variant="!leftBarTag && !$vuetify.breakpoint.smAndDown"
    >
      <v-list dense>
        <v-list-item-group
          v-model="ColectItem"
          :mandatory='itemmandatory'
        >
          <v-list-item
            v-for="(funcItem, index) in funcLinks"
            :color="funcItem.funcColor"
            :key="index"
            @click="handleColectItem(index)"
          >
            <v-list-item-avatar size="25">
              <v-img :src="funcItem.funcImg"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="black--text">
              <v-list-item-title :class="funcItem.funcClass">{{ funcItem.funcName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-1"></v-divider>

          <v-list-item
            @click="handlebeProUser"
            color="amber"
          >
            <v-list-item-avatar size="25">
              <v-icon size="25" color="amber darken-3" class="mr-1">mdi-star-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="deep-orange--text text-darken-2">
              <v-list-item-title :class="proClass">{{ $t("message.leftBar_Pricing") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-divider class="my-1"></v-divider> -->

          <v-list-item
            @click="handleAbout"
          >
            <v-list-item-avatar size="25">
              <v-icon size="25" color="grey darken-2" class="mr-1">mdi-information</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="black--text">
              <v-list-item-title :class="aboutClass">{{ $t("message.leftBar_AboutUs") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="handleContact"
          >
            <v-list-item-avatar size="25">
              <v-icon size="25" color="grey darken-2" class="mr-1">mdi-email</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="black--text">
              <v-list-item-title :class="contactClass">{{ $t("message.leftBar_ContactUs") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: 'YA_leftNavBar',
  data() {
    return {
      leftBarTag: false,
      funcLinks: [
        {
          'funcName': 'PowerChat',
          'funcColor': 'teal',
          'funcImg': require("@/assets/TagPic/pic_tag_powerchat.svg"),
          'funcClass': 'text-body-2'
        },
        // {
        //   'funcName': 'PowerFile',
        //   'funcColor': 'red',
        //   'funcImg': require("@/assets/TagPic/pic_tag_file.svg"),
        //   'funcClass': 'text-body-2'
        // },
        {
          'funcName': 'PowerTelegram',
          'funcColor': 'blue',
          'funcImg': require("@/assets/TagPic/pic_tag_telegram.svg"),
          'funcClass': 'text-body-2'
        },
        {
          'funcName': 'PowerWriter',
          'funcColor': 'yellow',
          'funcImg': require("@/assets/TagPic/pic_tag_writer.svg"),
          'funcClass': 'text-body-2'
        },
        {
          'funcName': 'PowerRewrite',
          'funcColor': 'purple',
          'funcImg': require("@/assets/TagPic/pic_tag_rewrite.svg"),
          'funcClass': 'text-body-2'
        },
        {
          'funcName': 'PowerSummarizer',
          'funcColor': 'deep-purple',
          'funcImg': require("@/assets/TagPic/pic_tag_summarizer.svg"),
          'funcClass': 'text-body-2'
        },
        {
          'funcName': 'PowerGrammarFix',
          'funcColor': 'green',
          'funcImg': require("@/assets/TagPic/pic_tag_GrammarFix.svg"),
          'funcClass': 'text-body-2'
        },
        {
          'funcName': 'PowerTranslate',
          'funcColor': 'cyan',
          'funcImg': require("@/assets/TagPic/pic_tag_translate.svg"),
          'funcClass': 'text-body-2'
        },
        {
          'funcName': 'PowerCodeExplainer',
          'funcColor': 'grey',
          'funcImg': require("@/assets/TagPic/pic_tag_CodeExplainer.svg"),
          'funcClass': 'text-body-2'
        },
        {
          'funcName': 'PowerImitate',
          'funcColor': 'red',
          'funcImg': require("@/assets/TagPic/pic_tag_imitate.svg"),
          'funcClass': 'text-body-2'
        },
        {
          'funcName': 'PowerFewShot',
          'funcColor': 'indigo',
          'funcImg': require("@/assets/TagPic/pic_tag_FewShot.svg"),
          'funcClass': 'text-body-2'
        },
        // {
        //   'funcName': 'PowerPicture',
        //   'funcColor': 'pink',
        //   'funcImg': require("@/assets/TagPic/pic_tag_powerPicture.svg"),
        //   'funcClass': 'text-body-2'
        // },
        // {
        //   'funcName': 'PowerTelegram',
        //   'funcColor': 'blue',
        //   'funcImg': require("@/assets/TagPic/pic_tag_telegram.svg"),
        //   'funcClass': 'text-body-2'
        // },
      ],
      ColectItem: 0,
      itemmandatory: true,

      proClass: 'text-body-2',
      aboutClass: 'text-body-2',
      contactClass: 'text-body-2',

      leftVmodel: undefined,
    }
  },
  computed: {
  },
  created() {
    this.$store.watch(
      (state) => state.systemMsg.leftBarTag, () => {
        this.leftBarTag = this.$store.getters.getleftBarTag;
        this.leftBarTagChanged();
      }, {
        deep: true
      }
    );
    this.leftBarTag = this.$store.getters.getleftBarTag;
    this.leftBarTagChanged();

    this.$store.watch(
      (state) => state.systemMsg.nowRouter, () => {
        this.handleFocus();
      }, {
        deep: true
      }
    );
    this.handleFocus();
  },
  watch: {
    leftVmodel(newValue, oldValue) {
      if (!newValue) {
        this.$store.commit('HandleleftBarTag', false);
      }
    }
  },
  mounted() {

  },
  methods: {
    leftBarTagChanged() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.leftVmodel = this.leftBarTag
      } else {
        this.leftVmodel = undefined  
      }
    },
    handleFocus() {
      // console.log(this.$store.getters.getnowRouter)
      if (this.$store.getters.getnowRouter) {
        if (this.$store.getters.getnowRouter.startsWith("Power")) {
          this.itemmandatory = true;
          this.funcLinks.forEach ((item, index)=>{
            if (item.funcName == this.$store.getters.getnowRouter) {
              this.ColectItem = index;
              item.funcClass = 'text-body-2 font-weight-black';
            } else {
              item.funcClass = 'text-body-2';
            }
          })
          this.proClass = 'text-body-2';
          this.aboutClass = 'text-body-2';
          this.contactClass = 'text-body-2';
        } else if(this.$store.getters.getnowRouter == 'Pricing') {
          this.itemmandatory = true;
          this.ColectItem = this.funcLinks.length;
          this.proClass = 'text-body-2 font-weight-black'
          this.funcLinks.forEach ((item, index)=>{
            item.funcClass = 'text-body-2'
          });
          this.aboutClass = 'text-body-2';
          this.contactClass = 'text-body-2';
        } else if(this.$store.getters.getnowRouter == 'About') {
          this.itemmandatory = true;
          this.ColectItem = this.funcLinks.length + 1;
          this.aboutClass = 'text-body-2 font-weight-black'
          this.funcLinks.forEach ((item, index)=>{
            item.funcClass = 'text-body-2'
          });
          this.proClass = 'text-body-2';
          this.contactClass = 'text-body-2';
        } else if(this.$store.getters.getnowRouter == 'Contact') {
          this.itemmandatory = true;
          this.ColectItem = this.funcLinks.length + 2;
          this.contactClass = 'text-body-2 font-weight-black'
          this.funcLinks.forEach ((item, index)=>{
            item.funcClass = 'text-body-2'
          });
          this.aboutClass = 'text-body-2';
          this.proClass = 'text-body-2';
        } else {
          this.itemmandatory = false;
          this.ColectItem = null;
          this.funcLinks.forEach ((item, index)=>{
            item.funcClass = 'text-body-2'
          })
          this.proClass = 'text-body-2';
        }
      } 
    },
    handleColectItem(index) {
      this.$nextTick(() => {
        let routerName = this.funcLinks[this.ColectItem].funcName

        if (this.$router.currentRoute.path !== '/' + routerName) {
          this.$router.push('/' + routerName);
        }
      });
      // if (this.$router.currentRoute.path !== '/chat') {
      //   this.$router.push('/chat');
      // }
      // if (this.$vuetify.breakpoint.smAndDown) {
      //   this.PhoneChatLeftBar = false;
      //   this.$store.commit('HandleLeftBarTag', this.PhoneChatLeftBar);
      // }
      // this.$nextTick(() => {
      //   for (let i = 0; i < this.ChatLinks.length; i++) {
      //     if (i === index && this.ColectItem !==undefined) {
      //       this.ChatLinks[i].showButtons = true;
      //     } else {
      //       this.ChatLinks[i].showButtons = false;
      //     }
      //   }
      // });
      
      // this.$store.commit('HandleChatID', this.ChatLinks[index].chatID);
      // this.$store.commit('HandleChatName', this.ChatLinks[index].chatName);
    },
    handlebeProUser() {
      if (this.$route.path !== '/Pricing') {
        this.$router.push({ path: '/Pricing'});
      }
    },
    handleAbout() {
      if (this.$route.path !== '/About') {
        this.$router.push({ path: '/About'});
      }
    },
    handleContact() {
      if (this.$route.path !== '/Contact') {
        this.$router.push({ path: '/Contact'});
      }
    },
  }

};
</script>

<style scoped>
</style>