<template>
  <div>
    <v-container>
      <v-textarea
        class="mt-2"
        v-model="PowerRewriteRequire"
        color="purple"
        height="400"
        outlined
        clearable
        :label="$t('message.PowerRewrite_RewriteText')"
        maxlength="5000"
        no-resize
        counter
      ></v-textarea>
    </v-container>

    <v-container class="d-flex justify-space-between mt-n3">
      <v-btn text color="purple darken-1" rounded :loading="ButtonLinksLoading" @click="handleUploadFile">
        <v-icon size="25" class="mr-1">mdi-upload</v-icon>
        <h4 class="text-capitalize font-weight-black">{{ $t("message.PowerRewrite_UploadFile") }}</h4>
      </v-btn>
      <input type="file" accept=".pdf" ref="fileInput" @change="handleFileChange" style="display: none" />
      <v-btn color="purple" class="mr-2" dark @click="handlePowerRewrite" :loading="powerRewriteLoading">
        <h4 class="text-capitalize white--text font-weight-black">{{ $t("message.PowerRewrite_Rewrite") }}</h4>
        <v-icon size="25" class="ml-1" color="white">mdi-arrow-right-bold-hexagon-outline</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
// import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';
import { fileLoadText } from '@/utils/PowerNomalUtils/LoadFileText_Nomal.js';
import { getDid } from '@/utils/SystemUtils/fingerprint.js'
import { getToken } from '@/utils/SystemUtils/token.js'
import { getBaseURL } from '@/utils/SystemUtils/url.js'
import { getCookie, setCookie, removeCookie } from '@/utils/SystemUtils/cookies.js'
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { setNotLoginLimit } from '@/utils/SystemUtils/userNotLogin.js'
import { createHash } from 'crypto-browserify';

import {marked} from 'marked';
import { mangle } from "marked-mangle";
import { gfmHeadingId } from "marked-gfm-heading-id";
import markedLinkifyIt from "marked-linkify-it";
import markedKatex from "marked-katex-extension";
import 'katex/dist/katex.min.css';

import 'highlight.js/styles/monokai-sublime.css';


export default {
  name: 'PowerRewriteLeft',
  components: {
    // YA_ScrollComponent,
  },
  data() {
    return {
      PowerRewriteRequire: '',
      powerRewriteHeadMsg: {},
      powerRewriteRes: '',

      ButtonLinksLoading: false,
      powerRewriteLoading: false,
      fileMax: 100 * 1024 * 1024,

      userID: '',
      token: '',
      userName: '',
      isLogin: false,
      ChatUseNum: 0,
      NotLoginChatNum: 0,

      isRunning: false,
    }
  },
  created() {
    this.$store.watch(
      (state) => state.powerRewriteMsg.powerRewriteHeadMsg, () => {
        this.powerRewriteHeadMsg = this.$store.getters.getpowerRewriteHeadMsg;
      }, {
        deep: true
      }
    );
    this.powerRewriteHeadMsg = this.$store.getters.getpowerRewriteHeadMsg;

    this.$store.watch(
      (state) => state.powerRewriteMsg.powerRewriteTryDemo, () => {
        if (this.$store.getters.getpowerRewriteTryDemo) {
          this.PowerRewriteRequire = "In today's increasingly digital world, we frequently interact with a variety of smart devices. At the core of these devices is artificial intelligence technology, which can learn and simulate certain aspects of human intelligence through algorithms and models. For example, voice assistants in smartphones are able to understand our commands and perform tasks such as setting an alarm or playing music. In addition, recommendation systems can recommend products to us based on our browsing history and purchasing habits. The purpose of these artificial intelligence systems is to improve the quality of our lives and make daily tasks more convenient.";
          this.handlePowerRewrite()
          this.$store.commit('HandlepowerRewriteTryDemo', false);
        }
      }, {
        deep: true
      }
    );

    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

    this.$store.watch(
      (state) => state.userMsg.ChatUseNum, () => {
        this.ChatUseNum = this.$store.getters.getChatUseNum;
      }, {
        deep: true
      }
    );
    this.ChatUseNum = this.$store.getters.getChatUseNum;

    this.$store.watch(
      (state) => state.userMsg.NotLoginChatNum, () => {
        this.NotLoginChatNum = this.$store.getters.getNotLoginChatNum;
      }, {
        deep: true
      }
    );
    this.NotLoginChatNum = this.$store.getters.getNotLoginChatNum;
  },
  mounted() {
    let did = getDid().then(res => {
      setCookie('did', res)
      setLocalStorage('did', res)
    });
    let lastSession=Date.now()
    setCookie('lastSession', lastSession)

    // for (let key in this.chatContent) {
    //   removeLocalStorage(key + 'chatlist')
    // }
    const localstoragekeys = Object.keys(localStorage);

    localstoragekeys.forEach(function(key) {
      if (key.endsWith("chatlist")) {
        removeLocalStorage(key);
      }
    });

    marked.use(mangle());
    marked.use(gfmHeadingId({prefix: "my-prefix-"}));
    marked.use(markedLinkifyIt());
    marked.use(markedKatex({throwOnError: false}));

  },

  methods: {
    handleUploadFile() {
      if (this.isLogin) {
        this.$refs.fileInput.click();
      } else {
        this.$store.commit('HandlePromptBoomSnackBar', 'Please Login')
      }
    },
    async handleFileChange(event) {
        this.ButtonLinksLoading = true;
        const fileInput = event.target;
        if (fileInput.files.length > 0) {
          if (fileInput.files[0].size < this.fileMax) {
            const fileContent = await fileLoadText(fileInput.files[0]);
            if (fileContent.code == 200) {
              this.PowerRewriteRequire = fileContent.content.join("");
              this.ButtonLinksLoading = false;
            } else {
              this.$store.commit('HandlePromptBoomSnackBar', fileContent.msg)
              this.ButtonLinksLoading = false;
              this.$refs.fileInput.value = '';
            }
          } else {
            this.$store.commit('HandlePromptBoomSnackBar', 'The file is too large, please choose a file smaller than 100MB.')
            this.selectedFile = null;
          }
        } else {
          this.ButtonLinksLoading = false;
          this.$refs.fileInput.value = '';
        }
    },
    handlePowerRewrite() {
      this.powerRewriteRes = '';
      this.$store.commit('HandlepowerRewriteRes', '');

      if (this.isLogin) {
        if (this.ChatUseNum > 0) {
          if (this.PowerRewriteRequire.length > 5000) {
            this.$store.commit('HandlePromptBoomSnackBar', 'The text is too long.')
          } else {
            if (this.PowerRewriteRequire.length > 0) {
              this.handlePowerChat();
            }
          }
        } else {
          this.$store.commit('HandlePromptBoomSnackBar', 'You have 0 free message left today')
        }
      } else if (this.NotLoginChatNum > 0) {
        if (this.PowerRewriteRequire.length > 5000) {
          this.$store.commit('HandlePromptBoomSnackBar', 'The text is too long.')
        } else {
          if (this.PowerRewriteRequire.length > 0) {
            this.handlePowerChat();
          }
        }
      } else {
        this.$store.commit('HandlePromptBoomSnackBar', 'Please Login')
      }
    },
    async handlePowerChat() {
      this.powerRewriteLoading = true;
      this.isRunning = true;

      let response;
      try {
        response = await this.requestPowerChat(this.PowerRewriteRequire)
      } catch (error) {
        this.try_error(error)
      } finally {
        this.try_finally(response)
      }
    },
    async requestPowerChat(question) {
      let chatList = []

      let lastSession=getCookie('lastSession')
      let currentSession=Date.now()
      if((currentSession-lastSession)>1000*60*20){
        setCookie('lastSession', currentSession)
      }

      chatList.push({
        "role": "user",
        "content": question
      })

      const t = Date.now()
      const token=getToken()
      const r = t + ":" + "question:" + token
      const sign = createHash('sha256').update(r).digest('hex')

      let request_json = {
        'did': getCookie('did'),
        'chatList': chatList,
        'botID': 'PowerRewrite',
        // 'botID': 'default',
        'requireMsg': this.powerRewriteHeadMsg,
        'special': {
          'referer':document.referrer||'no-referer',
          'path':location.href
        }
      };

      let raw_requst_json = {
        'data': this.utoa(JSON.stringify(request_json))
      };

      let baseURL=getBaseURL()
      let requestUrl =`${baseURL}/requestPowerChat`

      const response = await fetch(requestUrl, {
        method: 'POST',
        headers:{
          email: this.userID,
          token: this.token,
          isProUser: this.$store.getters.getIsProUser
        },
        body: JSON.stringify(raw_requst_json)
      });
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8")
      while (this.isRunning) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        let decodedValue = decoder.decode(value);
        this.powerRewriteRes+=decodedValue;
        this.$store.commit('HandlepowerRewriteRes', this.powerRewriteRes);
      }
      return response
    },
    try_error(error) {
      this.powerRewriteRes += "Network error[2001],you can try again or notify us by sending an email to hello@promptboom.com. Thank you";
      this.$store.commit('HandlepowerRewriteRes', this.powerRewriteRes);
    },
    try_finally(response) {
      if (response.status === 200) {
        this.$store.commit('HandleChatUseNum', this.ChatUseNum - 1);
        this.$store.commit('HandleNotLoginChatNum', this.NotLoginChatNum - 1);
        setNotLoginLimit();
      }

      this.powerRewriteLoading = false;
      this.isRunning = false;

      let tempAnswer = this.powerRewriteRes;
      if (tempAnswer.length == 0) {
        this.powerRewriteRes = "Network error[2001],you can try again or notify us by sending an email to hello@promptboom.com. Thank you";
        this.$store.commit('HandlepowerRewriteRes', this.powerRewriteRes);
      }
    },
    utoa(data) {
      return btoa(unescape(encodeURIComponent(data)));
    },
    atou(b64) {
      return decodeURIComponent(escape(atob(b64)));
    },
  }
};
</script>

<style scoped>
</style>