
<template>
  <div>
    <vue-custom-scrollbar ref="PageScroll" class="scroll-area" :settings="settings" @scroll.native="handleScroll">
      <slot></slot>
    </vue-custom-scrollbar>

  </div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  name: 'YA_ScrollComponent',
  components: {
    vueCustomScrollbar,
  },
  props: {
    scrollHeight: Number
  },
  data() {
    return {
      // customMessage: 0,
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false
      },
      isMouseScroll: false,    // 用于标识是否为鼠标滚动
      touchStartY: 0,          // 记录触摸开始时的位置
    }
  },
  created() {
    window.addEventListener("resize", this.updatePageHeight);
    // 在 mounted 后绑定 wheel 事件来判断是否为鼠标滚动
    this.$nextTick(() => {
      const scrollArea = this.$refs.PageScroll.$el;
      scrollArea.addEventListener('wheel', this.handleWheel);       // 监听鼠标滚动
      scrollArea.addEventListener('touchstart', this.handleTouchStart);  // 监听触摸开始
      scrollArea.addEventListener('touchmove', this.handleTouchMove);    // 监听触摸滚动
      scrollArea.addEventListener('touchend', this.handleTouchEnd);      // 监听触摸结束
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updatePageHeight);
    this.$refs.PageScroll.$el.removeEventListener('wheel', this.handleWheel); // 记得在销毁时移除事件
  },
  mounted() {
    this.$refs.PageScroll.$el.style.height = (window.innerHeight - this.scrollHeight) + 'px';
  },

  methods: {
    handleScroll(event) {
      if (!this.isMouseScroll) return;
      this.$emit('scroll', event); // 把滚动事件传递给父组件
    },
    setMouseScroll(value) {
      // 暴露方法来让父组件更改 isMouseScroll
      this.isMouseScroll = value;
    },
    handleWheel(event) {
      // 标记为鼠标滚动
      this.isMouseScroll = true;

      event.preventDefault();
    },
    handleTouchStart(event) {
      // 记录触摸开始的位置
      const touch = event.touches[0];
      this.touchStartY = touch.pageY;
    },
    handleTouchMove(event) {
      // 判断触摸滚动的方向和距离
      const touch = event.touches[0];
      const deltaY = touch.pageY - this.touchStartY;

      // 如果滚动距离足够大，认为是手动触摸滚动
      if (Math.abs(deltaY) > 5) {
        this.isMouseScroll = true;  // 鼠标滚动标志置为 false
        this.touchStartY = touch.pageY;  // 更新触摸开始位置
      }
    },
    handleTouchEnd() {
      // 触摸结束，重置标志
      this.touchStartY = 0; // 重置触摸开始位置
    },
    updatePageHeight() {
      this.$nextTick(() => {
        const PageScrollCom = this.$refs.PageScroll;
        if (PageScrollCom) {
          PageScrollCom.$el.style.height = (window.innerHeight - this.scrollHeight) + 'px';
        }  
      });
    },
    scrollScreen() {
      this.$nextTick(() => {
        this.$refs.PageScroll.$el.scrollTop = this.$refs.PageScroll.$el.scrollHeight+20;   
      })
    },
    comScrollDown() {
      this.$refs.PageScroll.$el.scrollTop = this.$refs.PageScroll.$el.scrollHeight;
    },
  }

};
</script>

<style scoped>
.scroll-area {
  position: relative;
  margin: auto;
}
</style>