<template>
  <div>
    <v-container>
      <v-textarea
        class="mt-2 mb-n7"
        counter
        filled
        v-model="powerImitateRes"
        no-resize
        height="400"
        hide-details="auto"
        color="pink"
      ></v-textarea>
    </v-container>
    <v-container class="d-flex mt-6 justify-end">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="pink darken-1" v-bind="attrs" v-on="on" class="mx-1 mt-n2" icon @click="handleCopy">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("message.PowerImitate_Copy") }}</span>
      </v-tooltip>
    </v-container>
  </div>
</template>

<script>
import {marked} from 'marked';
import DOMPurify from 'dompurify';
export default {
  name: 'PowerImitateRight',
  data() {
    return {
      powerImitateRes: '',
    }
  },
  created() {
    this.$store.watch(
      (state) => state.powerImitateMsg.powerImitateRes, () => {
        this.powerImitateRes = this.$store.getters.getpowerImitateRes;
      }, {
        deep: true
      }
    );
    this.powerImitateRes = this.$store.getters.getpowerImitateRes;
  },
  mounted() {

  },
  
  methods: {
    htmlfy(answer) {
      // console.log(DOMPurify.sanitize(marked.parse(answer)))
      return DOMPurify.sanitize(marked.parse(answer));      
    },
    handleCopy() {
      const anwser=this.htmlfy(this.powerImitateRes)
      const temp = document.createElement('div');
      temp.innerHTML =anwser ;
      document.body.appendChild(temp);
      const range = document.createRange();
      range.selectNode(temp);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      document.body.removeChild(temp);
      this.$store.commit('HandlePromptBoomSnackBar', 'Copied')
    }
  }

};
</script>

<style scoped>
</style>