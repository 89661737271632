<template>
  <div>
    <YA_ScrollComponent :scrollHeight="scrollHeight">
      <v-container>
        <p class="blue-grey--text my-8 text-center text--darken-4 text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">{{ $t("message.PowerChat_CreateBotTitle")}}</p>
        <div class="d-flex justify-center my-8">
          <v-avatar size="68">
            <v-img alt="avatar" contain src="@/assets/RobotIcon.png" />
          </v-avatar>
        </div>
        <v-row class="justify-center">
          <v-col cols="10" sm="10" md="10" lg="10" xl="8">
            <h6 class="my-2 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">{{ $t("message.PowerChat_CreateBotBotName")}}</h6>
            <!-- <h6 class="my-2 grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-2 text-lg-body-2 text-xl-body-2">Bot Name should be 4-20 characters long, and may include alphanumeric characters, dashes or underscores.</h6> -->

            <v-text-field 
              :label="$t('message.PowerChat_CreateBotBotNameTip')"
              color="teal"
              class="my-2" 
              v-model="RobotHandle"
              :rules="HandleRules" 
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="10" sm="10" md="10" lg="10" xl="8">
            <h6 class="my-2 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">{{ $t("message.PowerChat_CreateBotBotPrompt")}}</h6>
            <h6 class="my-2 grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-2 text-lg-body-2 text-xl-body-2">{{ $t("message.PowerChat_CreateBotBotPromptDescription")}}</h6>

            <v-textarea
              class="my-2"
              color="teal"
              solo
              :label="$t('message.PowerChat_CreateBotBotPromptTip')"
              v-model="RobotPrompt"
              auto-grow
              maxlength="1000"
              counter
              :rules="PromptRules"
              hide-details="auto"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </YA_ScrollComponent>

    <v-footer color="white" height="72">
      <v-row class="justify-center">
        <v-col cols="10" sm="10" md="10" lg="10" xl="8">
          <v-btn color="teal" rounded block large :loading="RobotCreateLoading" :disabled="btnRules()" @click="handleCreateRobot">
            <h6 class="text-capitalize white--text text-body-1 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 font-weight-black">{{ $t("message.PowerChat_CreateBotButton")}}</h6>
            <v-icon color="white" class="mx-2">
              mdi-robot-confused
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import YA_Snackbar from '@/components/WebBar/YA_Snackbar.vue';
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';

import { requestPost } from '@/utils/SystemUtils/request.js';


export default {
  name: 'YA_CreateBot',
  components: {
    YA_ScrollComponent,
  },
  data() {
    return {
      RobotHandle: "",
      RobotPrompt: "",
      HandleRules: [
        value => !!value || 'Required',
        value => this.isValidHandle(value) || 'Enter the correct Handle',
      ],
      PromptRules: [
        value => !!value || 'Required',
      ],
      RobotCreateLoading: false,

      isLogin: false,
      userID: '',
      token: '',
    }
  },
  computed: {
    scrollHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 190;
      } else {
        return 130;
      }
    },
  },
  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
        // this.RobotLinks = this.$store.getters.getRobotLinks;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

  },
  mounted() {
  },

  methods: {
    async handleCreateRobot() {
      if (this.isLogin) {
        this.RobotCreateLoading = true;

        let request_header = {
          email: this.userID,
          token: this.token
        };
        let request_json = {
          'botName': this.RobotHandle,
          'botPrompt': this.RobotPrompt,
        };

        let request_url = '/requestCreateBot';
        const res = await requestPost(request_header, request_json, request_url);
        if (res.statusCode == 1) {
          this.$store.commit('HandlerefreshRobotLinks', true);
          // this.$store.commit('HandleRobotCreateNum', this.RobotCreateNum - 1);
          if (this.$router.currentRoute.path !== '/chat/PowerChat') {
            this.$router.push('/chat/PowerChat');
          }
          this.RobotCreateLoading = false;
          this.RobotHandle = '';
          this.RobotPrompt = '';
        } else {
          this.RobotCreateLoading = false;
          this.$store.commit('HandlePromptBoomSnackBar', 'You exceeded your current Bots quota')
        }
      } else {
        this.$store.commit('HandlePromptBoomSnackBar', 'Please Login')
        if (this.$route.path !== '/Login') {
          this.$router.push({ path: '/Login'});
        }
      }
    },
    isValidHandle(Handle) {
      // const regex = /^[a-zA-Z0-9-_]{4,20}$/;
      const regex = /^.{1,100}$/;
      return regex.test(Handle);
    },
    btnRules() {
      // const regex = /^[a-zA-Z0-9-_]{4,20}$/;
      const regex = /^.{4,100}$/;
      return !((this.RobotHandle=== "" ? false : true) && (regex.test(this.RobotHandle)) && (this.RobotPrompt=== "" ? false : true));
    }
  }
};
</script>

<style scoped>

</style>