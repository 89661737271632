<template>
  <div>
    <v-card elevation="0" rounded="lg">
      <v-container class="d-flex flex-wrap justify-space-between">
        <div class="d-flex flex-wrap justify-center">
          <span class="black--text text--darken-2 text-body-1 font-weight-bold mt-1">
            <v-icon color="black darken-2" class="mt-n1">mdi-collage</v-icon>
            {{ $t("message.PowerCodeExplainer_LevelOfDetail") }}:
          </span>
          <div class="mx-4 mt-1 " style="width: 200px; height: 25px;">
            <v-slider
              v-model="lengthSlider"
              :max="2"
              step="1"
              track-color="black darken-4"
              ticks="always"
              tick-size="5"
              dense
              color="black"
              thumb-label
              thumb-size="50"
              @change="handlePowerCodeExplainerChange"
            >
              <template v-slot:thumb-label="{ value }">
                {{ thumbLable[Math.min(Math.floor(value), 2)] }}
              </template>
            </v-slider>
          </div>
        </div>
        <div v-if="!$vuetify.breakpoint.smAndDown">

          <v-btn color="black" class="mr-2" small @click="handleTryDemo">
            <h4 class="text-capitalize white--text font-weight-black">{{ $t("message.PowerCodeExplainer_TryDemo") }}</h4>
            <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
          </v-btn>
        </div>
        <v-container v-else class="d-flex justify-center mt-1 mb-n3">
          <v-btn color="black" class="mr-2" small @click="handleTryDemo">
            <h4 class="text-capitalize white--text font-weight-black">{{ $t("message.PowerCodeExplainer_TryDemo") }}</h4>
            <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
          </v-btn>
        </v-container>
        
        
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PowerCodeExplainerHead',
  data() {
    return {
      modesSelected: false,

      lengthSlider: 0,
      thumbLable: [this.$t('message.PowerCodeExplainer_Simple'), this.$t('message.PowerCodeExplainer_Mid'), this.$t('message.PowerCodeExplainer_Detailed')]
    }
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      this.thumbLable = [this.$t('message.PowerCodeExplainer_Simple'), this.$t('message.PowerCodeExplainer_Mid'), this.$t('message.PowerCodeExplainer_Detailed')]
    }
  },
  mounted() {
    this.handlePowerCodeExplainerChange();
  },

  methods: {
    handlePowerCodeExplainerChange() {
      this.$nextTick(() => {
        let thumbLableSelected = this.thumbLable[Math.min(Math.floor(this.lengthSlider),2)];
        let powerCodeExplainerHeadMsg = {
          'detailLevel': ""
        }
        switch(thumbLableSelected) {
          case this.$t('message.PowerCodeExplainer_Simple'):
            powerCodeExplainerHeadMsg.detailLevel = "simple";
            break;
          case this.$t('message.PowerCodeExplainer_Mid'):
            powerCodeExplainerHeadMsg.detailLevel = "mid";
            break;
          case this.$t('message.PowerCodeExplainer_Detailed'):
            powerCodeExplainerHeadMsg.detailLevel = "detailed";
            break;
        }
        this.$store.commit('HandlepowerCodeExplainerHeadMsg', powerCodeExplainerHeadMsg);
      })

    },
    handleTryDemo() {
      this.lengthSlider =0;
      this.handlePowerCodeExplainerChange();
      this.$store.commit('HandlepowerCodeExplainerTryDemo', true);
    }

  }

};
</script>

<style scoped>

</style>