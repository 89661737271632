<template>
  <v-container>
    <!-- 圆形图标触发器 -->
    <v-menu offset-y>
    
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          elevation="0"
          color="transparent"
          dark
          class="language-button"
          fab
          small
        >
          <!-- 图标改为深灰色 -->
          <v-icon size="35" color="blue">mdi-earth</v-icon>
        </v-btn>
      </template>

      <!-- 自定义下拉菜单 -->
      <v-list dense width="120">
        <v-list-item
          v-for="language in languages"
          :key="language.value"
          @click="selectLanguage(language.value)"
          :class="{ 'current-language': $i18n.locale === language.value }" 
        >
          <!-- 减小行高度 -->
          <v-list-item-title class="narrow-list-item">
            {{ language.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      languages: [
        { label: "English", value: "en" },
        { label: "Português", value: "pt" },
        { label: "العربية", value: "ar" },
      ],
    };
  },
  methods: {
    selectLanguage(language) {
      this.$i18n.locale = language;
      localStorage.setItem("promptBoomLanguage", language);
      // 更新 HTML 的方向（支持 RTL）
      // document.documentElement.setAttribute(
      //   "dir",
      //   language === "ar" ? "rtl" : "ltr"
      // );
    },
  },
  mounted() {
    // 初始化 HTML 的方向
    // document.documentElement.setAttribute(
    //   "dir",
    //   this.$i18n.locale === "ar" ? "rtl" : "ltr"
    // );
  },
};
</script>

<style scoped>
/* .language-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1000;
} */
/* 减小下拉项的行高 */
.narrow-list-item {
  font-size: 14px; /* 可选：调整字体大小 */
  line-height: 30px; /* 行高更窄 */
  padding-top: 5px;
  padding-bottom: 5px;
}

/* 高亮当前选择语言 */
.current-language {
  background-color: rgba(0, 0, 0, 0.1); /* 浅灰色背景 */
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
}

/* 鼠标悬停样式 */
.v-list-item:hover {
  background-color: rgba(0, 0, 0, 0.2); /* 深灰色背景 */
  margin-left: 5px;
  margin-right: 5px;
}
</style>
