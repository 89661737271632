<template>
  <div>
    <v-container>
      <v-textarea
        class="mt-2 mb-n7"
        filled
        v-model="powerSummarizerRes"
        no-resize
        height="400"
        counter
        hide-details="auto"
        color="deep-purple"
      ></v-textarea>
    </v-container>
    <v-container class="d-flex mt-6 justify-space-between">
      <div class="ml-2">
        <span class="deep-purple--text text--darken-3 font-weight-bold" v-if="modesChoice">
          {{ answerSentenseLength }}
          {{ $t("message.PowerSummarizer_Sentense") }}<span v-if="answerSentenseLength > 1">{{ $t("message.PowerSummarizer_S") }}</span>
        </span>
        <span class="deep-purple--text text--darken-3 font-weight-bold" v-else>
          {{ answerPointLength }}
          {{ $t("message.PowerSummarizer_Point") }}<span v-if="answerPointLength > 1">{{ $t("message.PowerSummarizer_S") }}</span>
        </span>
      </div>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="deep-purple darken-1" v-bind="attrs" v-on="on" class="mx-1 mt-n2" icon @click="handleCopy">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("message.PowerSummarizer_Copy") }}</span>
      </v-tooltip>
    </v-container>
  </div>
</template>

<script>
import {marked} from 'marked';
import DOMPurify from 'dompurify';
export default {
  name: 'PowerSummarizerRight',
  data() {
    return {
      powerSummarizerRes: '',

      modesChoice: true,
    }
  },
  computed: {
    answerSentenseLength() {
      const sentences = this.powerSummarizerRes.match(/[.!?]+/g);
      if (sentences === null) {
        return 0;
      }
      return sentences.length;
    },
    answerPointLength() {
      const opinions  = this.powerSummarizerRes.match(/•/g);
      if (opinions  === null) {
        return 0;
      }
      return opinions .length;
    },
  },
  created() {
    this.$store.watch(
      (state) => state.powerSummarizerMsg.powerSummarizerRes, () => {
        this.powerSummarizerRes = this.$store.getters.getpowerSummarizerRes;
      }, {
        deep: true
      }
    );
    this.powerSummarizerRes = this.$store.getters.getpowerSummarizerRes;

    this.$store.watch(
      (state) => state.powerSummarizerMsg.powerSummarizerHeadMsg, () => {
        if (this.$store.getters.getpowerSummarizerHeadMsg.modes == 'Paragraph') {
          this.modesChoice = true
        } else {
          this.modesChoice = false
        }
      }, {
        deep: true
      }
    );
    if (this.$store.getters.getpowerSummarizerHeadMsg.modes == 'Paragraph') {
      this.modesChoice = true
    } else {
      this.modesChoice = false
    }
  },
  mounted() {

  },

  methods: {
    htmlfy(answer) {
      // console.log(DOMPurify.sanitize(marked.parse(answer)))
      return DOMPurify.sanitize(marked.parse(answer));      
    },
    handleCopy() {
      const anwser=this.htmlfy(this.powerSummarizerRes)
      const temp = document.createElement('div');
      temp.innerHTML = anwser;
      document.body.appendChild(temp);
      const range = document.createRange();
      range.selectNode(temp);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      document.body.removeChild(temp);
      this.$store.commit('HandlePromptBoomSnackBar', 'Copied')
    }
  }

};
</script>

<style scoped>
</style>