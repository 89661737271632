import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locales/en.json';
import pt from './locales/pt.json';
import ar from './locales/ar.json';

Vue.use(VueI18n);

const messages = {
  en,
  pt,
  ar,
};

// 自动检测浏览器语言
const browserLanguage = navigator.language || navigator.userLanguage; // 获取用户语言
const defaultLanguage = browserLanguage.startsWith('pt')
  ? 'pt'
  : browserLanguage.startsWith('ar')
  ? 'ar'
  : 'en'; // 默认语言是英语

const i18n = new VueI18n({
  locale: localStorage.getItem('promptBoomLanguage') || defaultLanguage, // 使用 localStorage 保存的语言或浏览器语言
  fallbackLocale: 'en', // 备用语言
  messages,
});

export default i18n;
