<template>
  <div>
    <v-container>
      <span class="pink--text text-body-2 font-weight-black">
        {{ $t("message.PowerImitate_InputText") }}:
      </span>
      <v-textarea
        class="mt-1"
        v-model="PowerImitateRequire"
        color="pink"
        height="114"
        outlined
        clearable
        :label="$t('message.PowerImitate_PleaseEnterTheTextThatNeedsToBeImitated')"
        maxlength="2000"
        no-resize
        counter
      ></v-textarea>
      <span class="pink--text text-body-2 font-weight-black">
        {{ $t("message.PowerImitate_MimicText") }}:
      </span>
      <v-textarea
        class="mt-1"
        v-model="powerImitateMimicText"
        color="pink"
        height="210"
        outlined
        clearable
        :label="$t('message.PowerImitate_ToEnterYourMimicTextAndPressImitate')"
        maxlength="2000"
        no-resize
        counter
      ></v-textarea>
    </v-container>

    <v-container class="d-flex justify-end mt-n3">
      <v-btn color="pink" class="mr-2" dark @click="handlePowerImitate" :loading="powerImitateLoading">
        <h4 class="text-capitalize white--text font-weight-black">{{ $t("message.PowerImitate_Imitate") }}</h4>
        <v-icon size="25" class="ml-1" color="white">mdi-arrow-right-bold-hexagon-outline</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
// import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';
import { getDid } from '@/utils/SystemUtils/fingerprint.js'
import { getToken } from '@/utils/SystemUtils/token.js'
import { getBaseURL } from '@/utils/SystemUtils/url.js'
import { getCookie, setCookie, removeCookie } from '@/utils/SystemUtils/cookies.js'
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { setNotLoginLimit } from '@/utils/SystemUtils/userNotLogin.js'
import { createHash } from 'crypto-browserify';

import {marked} from 'marked';
import { mangle } from "marked-mangle";
import { gfmHeadingId } from "marked-gfm-heading-id";
import markedLinkifyIt from "marked-linkify-it";
import markedKatex from "marked-katex-extension";
import 'katex/dist/katex.min.css';

import 'highlight.js/styles/monokai-sublime.css';


export default {
  name: 'PowerImitateLeft',
  components: {
    // YA_ScrollComponent,
  },
  data() {
    return {
      PowerImitateRequire: '',
      powerImitateMimicText: '',
      powerImitateHeadMsg: {},
      powerImitateRes: '',

      powerImitateLoading: false,

      userID: '',
      token: '',
      userName: '',
      isLogin: false,
      ChatUseNum: 0,
      NotLoginChatNum: 0,

      isRunning: false,
    }
  },
  created() {
    this.$store.watch(
      (state) => state.powerImitateMsg.powerImitateHeadMsg, () => {
        this.powerImitateHeadMsg = this.$store.getters.getpowerImitateHeadMsg;
      }, {
        deep: true
      }
    );
    this.powerImitateHeadMsg = this.$store.getters.getpowerImitateHeadMsg;

    this.$store.watch(
      (state) => state.powerImitateMsg.powerImitateTryDemo, () => {
        if (this.$store.getters.getpowerImitateTryDemo) {
          this.PowerImitateRequire = "Here is a pair of jeans";
          this.powerImitateMimicText = "French dress suits uniform kapok material, soft touch, coupled with charming embroidery, even more peerless grace! Vietnam embroidery dress to make you more slender and slim! India to open the slim dress of the young people, your fashion dress should go in that direction? Breasted dress";
          this.handlePowerImitate()
          this.$store.commit('HandlepowerImitateTryDemo', false);
        }
      }, {
        deep: true
      }
    );

    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

    this.$store.watch(
      (state) => state.userMsg.ChatUseNum, () => {
        this.ChatUseNum = this.$store.getters.getChatUseNum;
      }, {
        deep: true
      }
    );
    this.ChatUseNum = this.$store.getters.getChatUseNum;

    this.$store.watch(
      (state) => state.userMsg.NotLoginChatNum, () => {
        this.NotLoginChatNum = this.$store.getters.getNotLoginChatNum;
      }, {
        deep: true
      }
    );
    this.NotLoginChatNum = this.$store.getters.getNotLoginChatNum;
  },
  mounted() {
    let did = getDid().then(res => {
      setCookie('did', res)
      setLocalStorage('did', res)
    });
    let lastSession=Date.now()
    setCookie('lastSession', lastSession)

    // for (let key in this.chatContent) {
    //   removeLocalStorage(key + 'chatlist')
    // }
    const localstoragekeys = Object.keys(localStorage);

    localstoragekeys.forEach(function(key) {
      if (key.endsWith("chatlist")) {
        removeLocalStorage(key);
      }
    });

    marked.use(mangle());
    marked.use(gfmHeadingId({prefix: "my-prefix-"}));
    marked.use(markedLinkifyIt());
    marked.use(markedKatex({throwOnError: false}));

  },

  methods: {
    handlePowerImitate() {
      this.powerImitateRes = '';
      this.$store.commit('HandlepowerImitateRes', '');

      if (this.isLogin) {
        if (this.ChatUseNum > 0) {
          if (this.PowerImitateRequire.length > 2000 || this.powerImitateMimicText.length > 2000) {
            this.$store.commit('HandlePromptBoomSnackBar', 'The text is too long.')
          } else {
            if (this.PowerImitateRequire.length > 0) {
              this.handlePowerChat();
            }
          }
        } else {
          this.$store.commit('HandlePromptBoomSnackBar', 'You have 0 free message left today')
        }
      } else if (this.NotLoginChatNum > 0) {
        if (this.PowerImitateRequire.length > 2000 || this.powerImitateMimicText.length > 2000) {
          this.$store.commit('HandlePromptBoomSnackBar', 'The text is too long.')
        } else {
          if (this.PowerImitateRequire.length > 0) {
            this.handlePowerChat();
          }
        }
      } else {
        this.$store.commit('HandlePromptBoomSnackBar', 'Please Login')
      }
    },
    async handlePowerChat() {
      this.powerImitateLoading = true;
      this.isRunning = true;

      let response;
      try {
        response = await this.requestPowerChat(this.PowerImitateRequire)
      } catch (error) {
        this.try_error(error)
      } finally {
        this.try_finally(response)
      }
    },
    async requestPowerChat(question) {
      let chatList = []

      let lastSession=getCookie('lastSession')
      let currentSession=Date.now()
      if((currentSession-lastSession)>1000*60*20){
        setCookie('lastSession', currentSession)
      }

      chatList.push({
        "role": "user",
        "content": question
      })

      const t = Date.now()
      const token=getToken()
      const r = t + ":" + "question:" + token
      const sign = createHash('sha256').update(r).digest('hex')

      let request_json = {
        'did': getCookie('did'),
        'chatList': chatList,
        'botID': 'PowerImitate',
        // 'botID': 'default',
        'requireMsg': {
          powerImitateMimicText: this.powerImitateMimicText
        },
        'special': {
          'referer':document.referrer||'no-referer',
          'path':location.href
        }
      };

      let raw_requst_json = {
        'data': this.utoa(JSON.stringify(request_json))
      };

      let baseURL=getBaseURL()
      let requestUrl =`${baseURL}/requestPowerChat`

      const response = await fetch(requestUrl, {
        method: 'POST',
        headers:{
          email: this.userID,
          token: this.token,
          isProUser: this.$store.getters.getIsProUser
        },
        body: JSON.stringify(raw_requst_json)
      });
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8")
      while (this.isRunning) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        let decodedValue = decoder.decode(value);
        this.powerImitateRes+=decodedValue;
        this.$store.commit('HandlepowerImitateRes', this.powerImitateRes);
      }
      return response
    },
    try_error(error) {
      this.powerImitateRes += "Network error[2001],you can try again or notify us by sending an email to hello@promptboom.com. Thank you";
      this.$store.commit('HandlepowerImitateRes', this.powerImitateRes);
    },
    try_finally(response) {
      if (response.status === 200) {
        this.$store.commit('HandleChatUseNum', this.ChatUseNum - 1);
        this.$store.commit('HandleNotLoginChatNum', this.NotLoginChatNum - 1);
        setNotLoginLimit();
      }

      this.powerImitateLoading = false;
      this.isRunning = false;

      let tempAnswer = this.powerImitateRes;
      if (tempAnswer.length == 0) {
        this.powerImitateRes = "Network error[2001],you can try again or notify us by sending an email to hello@promptboom.com. Thank you";
        this.$store.commit('HandlepowerImitateRes', this.powerImitateRes);
      }
    },
    utoa(data) {
      return btoa(unescape(encodeURIComponent(data)));
    },
    atou(b64) {
      return decodeURIComponent(escape(atob(b64)));
    },
  }
};
</script>

<style scoped>
</style>