<template>
  <v-container style="max-width: 1200px;">
    <v-row class="justify-center">
      <v-col cols="10" sm="10" md="10" lg="10" xl="10">
        <v-container class="d-flex justify-center flex-wrap">
          <span class="pink--text text--darken-1 text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">PowerImitate</span>
        </v-container>
        <v-container class="d-flex justify-center flex-wrap mt-n2">
          <span class="grey-blue--text text--darken-4 text-body-1 text-center">{{ $t("message.PowerImitate_Content1_Description") }}🦎</span>
        </v-container>
        <v-img
          height="300"
          contain
          src="@/assets/contentPic/pic_imitate_mainPhoto.png"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'YA_ImitateContent1',
  data() {
    return {
    }
  },
  mounted() {

  },

  methods: {

  }

};
</script>

<style scoped>

</style>