<template>
  <div>
    <v-container v-if="!PowerChatLeftBarVmodel && $vuetify.breakpoint.smAndDown">
      <v-btn color="teal" class="mx-1" icon @click="handlePowerChatLeftBarVmodel">
        <v-icon >mdi-dots-horizontal-circle</v-icon>
      </v-btn>
    </v-container>
    <v-navigation-drawer
      v-if="PowerChatLeftBarVmodel || !$vuetify.breakpoint.smAndDown"
      v-model="PowerChatLeftBarVmodel"
    >
    <!-- <v-card elevation="1" width="100%" height="100%" rounded="lg" outlined> -->
      <div>
        <v-sheet rounded="xl">
          <v-container>
            <v-row class="justify-center">
              <v-col cols="6">
                <v-btn color="grey lighten-4" height="100%" width="100%" @click="handleExplore" >
                  <div class="d-flex flex-column pa-2">
                    <v-icon class="ma-2">
                      mdi-earth
                    </v-icon>
                    <h6 class="text-capitalize font-weight-black">{{ $t("message.PowerChat_Explore") }}</h6>
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="grey lighten-4" height="100%" width="100%" @click="handleCreateRob">
                  <div class="d-flex flex-column pa-2">
                    <v-icon class="ma-2">
                      mdi-robot-love
                    </v-icon>
                    <h6 class="text-capitalize font-weight-black">{{ $t("message.PowerChat_Create") }}</h6>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </div>
      <v-divider></v-divider>
      <YA_ScrollComponent :scrollHeight="150">
        <div v-if="!isLogin">
          <v-list>
            <v-list-item-group v-model="ColectItem" color="teal" :mandatory='itemmandatory'>
              <v-list-item
                v-for="(RobotItem, index) in RobotLinks"
                :key="index"
                @click="handleColectItem(index)"
              >
                <v-list-item-avatar size="40">
                  <v-img :src='RobotItem.photoUrl'></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ RobotItem.botName }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="RobotItem.botID !== 'default' && RobotItem.showButtons">
                  <v-list-item-action-text>
                    <v-btn
                      class="mx-2"
                      icon
                      small
                      color="lightgrey"
                      @click="handleDeleteRob(RobotItem.botName, RobotItem.botID)"
                    >
                      <v-icon dark>
                        mdi-window-close
                      </v-icon>
                    </v-btn>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <!-- <YA_webEmpty class="pt-10"/> -->

        </div>
        <div v-else>
          <v-skeleton-loader
            v-if="RobotLinksLoading || RobotLinksLoginLoading"
            type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
          ></v-skeleton-loader>
          <v-list v-else>
            <v-list-item-group v-model="ColectItem" color="teal" :mandatory='itemmandatory'>
              <v-list-item
                v-for="(RobotItem, index) in RobotLinks"
                :key="index"
                @click="handleColectItem(index)"
              >
                <v-list-item-avatar size="40">
                  <v-img :src='RobotItem.photoUrl'></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ RobotItem.botName }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="RobotItem.botID !== 'default' && RobotItem.showButtons">
                  <v-list-item-action-text>
                    <v-btn
                      class="mx-2"
                      icon
                      small
                      color="lightgrey"
                      @click="handleDeleteRob(RobotItem.botName, RobotItem.botID)"
                    >
                      <v-icon dark>
                        mdi-window-close
                      </v-icon>
                    </v-btn>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </YA_ScrollComponent>
    <!-- </v-card> -->
    </v-navigation-drawer>

    <v-dialog
      v-model="delete_dialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Are you sure to delete Robot {{ RobotDeleteName }} ?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="teal"
            text
            @click="delete_dialog = false"
            class='text-capitalize'
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="teal"
            @click="handleSureDelete"
            :loading="SureDeleteloading"
            class='text-capitalize white--text'
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';
import YA_webEmpty from '@/components/WebBar/YA_webEmpty.vue';

import { nextTick } from 'vue';
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'PowerChatLeftBar',
  components: {
    YA_ScrollComponent,
    // YA_webEmpty,
  },
  data() {
    return {
      RobotLinks: [],
      ColectItem: 0,
      itemmandatory: true,

      isLogin: false,
      userID: '',
      userName: '',
      token: '',

      RobotLinksLoading: false,
      RobotLinksLoginLoading: false,
      ButtonsLoginLoading: false,

      delete_dialog: false,
      RobotDeleteId: '',
      RobotDeleteName:'',
      SureDeleteloading: false,

      RobotCreateNum: 0,

      PowerChatLeftBarVmodel: undefined,
    }
  },
  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
        if (this.isLogin) {
          this.RobotLinksLoginLoading = false;
          this.ButtonsLoginLoading = false;
          
        } else {
          this.RobotLinksLoginLoading = true;
          this.ButtonsLoginLoading = true;
        }
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

    this.$store.watch(
      (state) => state.powerChatMsg.RobotLinks, () => {
        this.RobotLinks = this.$store.getters.getRobotLinks;
      }, {
        deep: true
      }
    );
    this.RobotLinks = this.$store.getters.getRobotLinks;
    this.ColectItem = 0;
    this.$store.commit('HandleRobotID', 'default');
    this.$store.commit('HandleRobotName', 'PowerChat');
    this.$store.commit('HandleRobotDescription', `Your Privacy-First AI Assistant in Incognito Mode`);

    this.$store.watch(
      (state) => state.powerChatMsg.RobotLinksLoading, () => {
        this.RobotLinksLoading = this.$store.getters.getRobotLinksLoading;
      }, {
        deep: true
      }
    );
    this.RobotLinksLoading = this.$store.getters.getRobotLinksLoading;
  },
  beforeDestroy() {
  },
  activated() {
    if (this.isLogin) {
      this.RobotLinksLoginLoading = false;
      this.ButtonsLoginLoading = false;
      
    } else {
      this.RobotLinksLoginLoading = true;
      this.ButtonsLoginLoading = true;
    }
  },
  mounted() {
  },
  watch: {
    '$route'(to, from) {
      if (to.path.startsWith("/PowerChat") && to.path !== '/PowerChat/PowerChatTalk') {
        this.itemmandatory = false;
        this.ColectItem = null;
        this.$nextTick(() => {
          for (let i = 0; i < this.RobotLinks.length; i++) {
            this.RobotLinks[i].showButtons = false;
          }
        });
      } else {
        this.itemmandatory = true;
      }
    }
  },
  methods: {
    handlePowerChatLeftBarVmodel() {
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.PowerChatLeftBarVmodel == false || this.PowerChatLeftBarVmodel == undefined) {
          this.PowerChatLeftBarVmodel = true;
        }
      } else {
        this.PowerChatLeftBarVmodel = false;
      }
    },
    handleDeleteRob(Name, ID) {
      this.delete_dialog = true;
      this.RobotDeleteId = ID;
      this.RobotDeleteName = Name;
    },
    async handleSureDelete() {
      this.SureDeleteloading = true;
      
      let request_header = {
        email: this.userID,
        token: this.token,
      };
      let request_json = {
        'botID': this.RobotDeleteId,
      };

      let request_url = '/requestDeleteBot';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.SureDeleteloading = false;
        this.delete_dialog = false;
        this.$store.commit('HandlerefreshRobotLinks', true);
        // this.$store.commit('HandleRobotCreateNum', this.RobotCreateNum + 1);
        this.$store.commit('HandlePromptBoomSnackBar', 'Success to delete the bot')

        this.ColectItem = 0;
        this.$store.commit('HandleRobotID', 'default');
        this.$store.commit('HandleRobotName', 'PowerChat');
        this.$store.commit('HandleRobotDescription', `Your Privacy-First AI Assistant in Incognito Mode`);
      } else {
        this.$store.commit('HandlePromptBoomSnackBar', 'Failed to delete the bot: ' + res.statusInfo)
        this.SureDeleteloading = false;
      }
    },

    handleColectItem(index) {
      if (this.$router.currentRoute.path !== '/PowerChat/PowerChatTalk') {
        this.$router.push('/PowerChat/PowerChatTalk');
      }
      // if (this.$vuetify.breakpoint.smAndDown) {
      //   this.PhoneChatLeftBar = false;
      // }

      this.$nextTick(() => {
        for (let i = 0; i < this.RobotLinks.length; i++) {
          if (i === index && this.ColectItem !==undefined) {
            this.RobotLinks[i].showButtons = true;
          } else {
            this.RobotLinks[i].showButtons = false;
          }
        }
      });
      this.$store.commit('HandleRobotID', this.RobotLinks[index].botID);
      this.$store.commit('HandleRobotName', this.RobotLinks[index].botName);
      this.$store.commit('HandleRobotDescription', this.RobotLinks[index].botDescription);
    },

    handleCreateRob() {
      if (this.$router.currentRoute.path !== '/PowerChat/CreateBot') {
        this.$router.push('/PowerChat/CreateBot');
      }
    },
    
    handleExplore() {
      if (this.$router.currentRoute.path !== '/PowerChat/ExploreBot') {
        this.$router.push('/PowerChat/ExploreBot');
      }
    },
  }

};
</script>

<style scoped>
</style>