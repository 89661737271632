
<template>
  <div>
    <v-container class="my-5" >
      <v-container class="d-flex flex-wrap justify-center">
        <v-container>
          <div>
            <p class="blue-grey--text text--darken-4 text-center text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black mt-8">{{ $t("message.About_PricintSubscribe") }}</p>
            <p class="text-center mt-6 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1">{{ $t("message.About_Title") }} 🚀</p>
          </div>
        </v-container>
        <v-btn color="blue" rounded @click="handlebeProUser">
          <v-icon size="25" color="white" class="mr-1">mdi-check-decagram-outline</v-icon>
          <h4 class="text-capitalize white--text font-weight-black">{{ $t("message.About_UpgradeButton") }}</h4>
        </v-btn>
      </v-container>
      <v-row class="justify-center" align="stretch">
        <v-col cols="10" sm="10" md="5" lg="5" xl="5">
          <v-card color="transparent" rounded="xl" outlined>
            <v-img src="@/assets/contentPic/pic_AboutPricing.png" rounded contain></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'YA_HomeContent',
  data() {
    return {

    }
  },
  mounted() {

  },

  methods: {
    handleContact() {
      if (this.$route.path !== '/Contact') {
        this.$router.push({ path: '/Contact'});
      }
    },
    handlebeProUser() {
      if (this.$route.path !== '/Pricing') {
        this.$router.push({ path: '/Pricing'});
      }
    },
  }

};
</script>

<style scoped>

</style>