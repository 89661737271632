<template>
  <div>
    <v-card elevation="0" rounded="lg" height="60">
      <v-container class="d-flex justify-space-between">
        <v-row class="justify-center" no-gutters>
          <v-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div style="max-width: 200px;">
              <v-select
                color="cyan"
                readonly
                class="font-weight-bold"
                v-model="inputLanguage"
                :menu-props="{ maxHeight: '300', offsetY: true }"
                :items="inputLanguageTags"
                :label="$t('message.PowerTranslate_FromLanguage')"
                dense
                outlined
              ></v-select>
            </div>
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="d-flex justify-end">
            <div style="max-width: 200px;">
              <v-select
                color="cyan"
                class="font-weight-bold"
                v-model="outputLanguage"
                :menu-props="{ maxHeight: '300', offsetY: true }"
                :items="outputLanguageTags"
                :label="$t('message.PowerTranslate_ToLanguage')"
                @change="handlePowerTranslateChange"
                dense
                outlined
              ></v-select>
            </div>
          </v-col>
        </v-row>
        
        
        <!-- <div class="mt-3">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="cyan darken-1" v-bind="attrs" v-on="on" class="mx-1 mt-n2" icon @click="handleExchange">
                <v-icon>mdi-swap-horizontal</v-icon>
              </v-btn>
            </template>
            <span>Exchange</span>
          </v-tooltip>
        </div> -->
        

      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PowerTranslateHead',
  data() {
    return {
      inputLanguage: this.$t('message.PowerTranslate_DetectLanguage'),
      outputLanguage: this.$t('message.PowerTranslate_English'),
      inputLanguageTags: [
        this.$t('message.PowerTranslate_DetectLanguage'),
        this.$t('message.PowerTranslate_Arabic'),
        this.$t('message.PowerTranslate_Cantonese'),
        this.$t('message.PowerTranslate_Chinese(Simplified)'),
        this.$t('message.PowerTranslate_Chinese(Traditional)'),
        this.$t('message.PowerTranslate_English'),
        this.$t('message.PowerTranslate_Filipino'),
        this.$t('message.PowerTranslate_French'),
        this.$t('message.PowerTranslate_German'),
        this.$t('message.PowerTranslate_Japanese'),
        this.$t('message.PowerTranslate_Hindi'),
        this.$t('message.PowerTranslate_Indonesian'),
        this.$t('message.PowerTranslate_Italian'),
        this.$t('message.PowerTranslate_Korean'),
        this.$t('message.PowerTranslate_Malay'),
        this.$t('message.PowerTranslate_Portuguese'),
        this.$t('message.PowerTranslate_Russian'),
        this.$t('message.PowerTranslate_Spanish'),
        this.$t('message.PowerTranslate_Thai'),
        this.$t('message.PowerTranslate_Vietnamese'),
      ],
      customMenuProps: { 
        maxHeight: '400', 
        offsetY: true ,
        opsitionY: 30,
        top: true
      }
    }
  },
  computed: {
    outputLanguageTags() {
      return this.inputLanguageTags.slice(1)
    },
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      this.inputLanguageTags = [
        this.$t('message.PowerTranslate_DetectLanguage'),
        this.$t('message.PowerTranslate_Arabic'),
        this.$t('message.PowerTranslate_Cantonese'),
        this.$t('message.PowerTranslate_Chinese(Simplified)'),
        this.$t('message.PowerTranslate_Chinese(Traditional)'),
        this.$t('message.PowerTranslate_English'),
        this.$t('message.PowerTranslate_Filipino'),
        this.$t('message.PowerTranslate_French'),
        this.$t('message.PowerTranslate_German'),
        this.$t('message.PowerTranslate_Japanese'),
        this.$t('message.PowerTranslate_Hindi'),
        this.$t('message.PowerTranslate_Indonesian'),
        this.$t('message.PowerTranslate_Italian'),
        this.$t('message.PowerTranslate_Korean'),
        this.$t('message.PowerTranslate_Malay'),
        this.$t('message.PowerTranslate_Portuguese'),
        this.$t('message.PowerTranslate_Russian'),
        this.$t('message.PowerTranslate_Spanish'),
        this.$t('message.PowerTranslate_Thai'),
        this.$t('message.PowerTranslate_Vietnamese'),
      ]
      this.inputLanguage = this.$t('message.PowerTranslate_DetectLanguage')
      this.outputLanguage = this.$t('message.PowerTranslate_English')
    }
  },
  mounted() {
    this.handlePowerTranslateChange()
  },

  methods: {
    // handleExchange() {
    // },
    handlePowerTranslateChange() {
      let powerTranslateHeadMsg = {
        'outputLanguage': this.outputLanguage
      }
      switch (this.outputLanguage) {
        case this.$t('message.PowerTranslate_Arabic'):
          powerTranslateHeadMsg.outputLanguage = 'Arabic'
          break
        case this.$t('message.PowerTranslate_Cantonese'):
          powerTranslateHeadMsg.outputLanguage = 'Cantonese'
          break
        case this.$t('message.PowerTranslate_Chinese(Simplified)'):
          powerTranslateHeadMsg.outputLanguage = 'Chinese(Simplified)'
          break
        case this.$t('message.PowerTranslate_Chinese(Traditional)'):
          powerTranslateHeadMsg.outputLanguage = 'Chinese(Traditional)'
          break
        case this.$t('message.PowerTranslate_English'):
          powerTranslateHeadMsg.outputLanguage = 'English'
          break
        case this.$t('message.PowerTranslate_Filipino'):
          powerTranslateHeadMsg.outputLanguage = 'Filipino'
          break
        case this.$t('message.PowerTranslate_French'):
          powerTranslateHeadMsg.outputLanguage = 'French'
          break
        case this.$t('message.PowerTranslate_German'):
          powerTranslateHeadMsg.outputLanguage = 'German'
          break
        case this.$t('message.PowerTranslate_Japanese'):
          powerTranslateHeadMsg.outputLanguage = 'Japanese'
          break
        case this.$t('message.PowerTranslate_Hindi'):
          powerTranslateHeadMsg.outputLanguage = 'Hindi'
          break
        case this.$t('message.PowerTranslate_Indonesian'):
          powerTranslateHeadMsg.outputLanguage = 'Indonesian'
          break
        case this.$t('message.PowerTranslate_Italian'):
          powerTranslateHeadMsg.outputLanguage = 'Italian'
          break
        case this.$t('message.PowerTranslate_Korean'):
          powerTranslateHeadMsg.outputLanguage = 'Korean'
          break
        case this.$t('message.PowerTranslate_Malay'):
          powerTranslateHeadMsg.outputLanguage = 'Malay'
          break
        case this.$t('message.PowerTranslate_Portuguese'):
          powerTranslateHeadMsg.outputLanguage = 'Portuguese'
          break
        case this.$t('message.PowerTranslate_Russian'):
          powerTranslateHeadMsg.outputLanguage = 'Russian'
          break
        case this.$t('message.PowerTranslate_Spanish'):
          powerTranslateHeadMsg.outputLanguage = 'Spanish'
          break
        case this.$t('message.PowerTranslate_Thai'):
          powerTranslateHeadMsg.outputLanguage = 'Thai'
          break
        case this.$t('message.PowerTranslate_Vietnamese'):
          powerTranslateHeadMsg.outputLanguage = 'Vietnamese'
          break
      }
      console.log(powerTranslateHeadMsg)
      this.$store.commit('HandlepowerTranslateHeadMsg', powerTranslateHeadMsg);
    }
  }

};
</script>

<style scoped>

</style>