<template>
  <div>
    <v-container>
      <v-container class="d-flex justify-center">
        <p class="mt-16 blue-grey--text text--darken-4 text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">{{ $t("message.PowerTelegram_Content1_Title") }}</p>
      </v-container>
      <v-container class="mt-8 mb-16">
        <v-row class="justify-center">
          <v-col cols="10" sm="10" md="3" lg="3" xl="3">
            <v-card rounded="xl" flat class="d-flex justify-center" height="500">
              <video ref="videoPlayer" muted @ended="restartVideo" height="100%">
                <source src="@/assets/TelegramVideo1.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </v-card>
          </v-col>
          <v-col cols="10" sm="10" md="4" lg="4" xl="4">
            <v-card rounded="xl" flat class="d-flex align-center" height="500">
              <v-container>
                <div class="d-flex justify-center">
                  <p class="blue--text text--lighten-1 text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black">PowerTelegram</p>
                </div>
                <div class="d-flex">
                  <p class="grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 font-weight-black text-xl-body-1 mt-7">{{ $t("message.PowerTelegram_Content1_Description") }}</p>
                </div>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="10" sm="10" md="3" lg="3" xl="3">
            <v-card rounded="xl" flat class="d-flex justify-center" height="500">
              <video ref="videoPlayer2" muted @ended="restartVideo2" height="100%">
                <source src="@/assets/TelegramVideo2.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'YA_TelegramContent2',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
    this.$refs.videoPlayer.play();
    this.$refs.videoPlayer2.play();
  },
  activated() {
    this.$refs.videoPlayer.play();
    this.$refs.videoPlayer2.play();

  },

  methods: {
    restartVideo() {
      setTimeout(() => {
        this.$refs.videoPlayer.currentTime = 0;
        this.$refs.videoPlayer.play();
      }, 1000);
    },
    restartVideo2() {
      setTimeout(() => {
        this.$refs.videoPlayer2.currentTime = 0;
        this.$refs.videoPlayer2.play();
      }, 1000);
      
    }
  }
};
</script>

<style scoped>
</style>