
<template>
  <div style="width: 100%;">
    <div class="d-flex justify-center" style="background-color: rgb(241,241,241);">
      <v-row class="py-12">
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
          <v-row class="justify-center">
            <v-col cols="6" sm="6" md="4" lg="4" xl="4" v-for="(webItem, index) in webSideLinks" :key="index" class="d-flex justify-center">
              <div>
                <span class="text-body-1 font-weight-bold">{{ webItem.groupTittle }}</span>
                  <div class="mt-3">
                  <div v-for="(item, index) in webItem.groupLinks" :key="index" class="mt-1">
                    <a 
                      :href="item.webLink" 
                      class="external-link"
                    >
                      <span class="text-body-1 grey--text text--darken-2">{{ item.webName }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="d-flex align-center justify-center mt-6">
          <div>
            <div>
              <span class="text-body-1 font-weight-bold">Contact us</span>
            </div>
            <div class="mt-2 d-flex">
              <a href="mailto:hello@promptboom.com" target="__blank">
                <v-btn icon class="mr-2">
                  <v-icon size="30" color="blue">mdi-email</v-icon>
                </v-btn>
              </a>
              <a href="https://twitter.com/promptboom" target="__blank">
                <v-btn icon class="mx-2">
                  <v-icon size="30" color="blue">mdi-twitter</v-icon>
                </v-btn>
              </a>
<!--               <a href="https://discord.com/invite/ZgqqKkhUA8" target="__blank">
                <v-btn icon class="mx-2">
                  <v-img width="28" height="28" contain src="@/assets/discord_icon.png"></v-img>
                </v-btn>
              </a> -->
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div style="background-color: rgb(224,224,224);">
      <div class="text-center py-6 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1">
        Copyright ©{{ new Date().getFullYear() }} —

        <router-link to="/" class='text-decoration-none black--text'>
          <strong>https://promptboom.com</strong>
        </router-link>  
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YA_footer',
  data() {
    return {
      webSideLinks: [
        {
          groupTittle: 'Products',
          groupLinks: [
            {
              webName: 'Power Chat',
              webLink: '/PowerChat',
            },
            {
              webName: 'Power Telegram',
              webLink: '/PowerTelegram',
            },
            // {
            //   webName: 'Power File',
            //   webLink: '/PowerFile',
            // },
            {
              webName: 'Power Writer',
              webLink: '/PowerWriter',
            },
            {
              webName: 'Power Rewrite',
              webLink: '/PowerRewrite',
            },

            {
              webName: 'Power Summarizer',
              webLink: '/PowerSummarizer',
            },
            {
              webName: 'Power GrammarFix',
              webLink: '/PowerGrammarFix',
            },
            {
              webName: 'Power Translate',
              webLink: '/PowerTranslate',
            },
            {
              webName: 'Power CodeExplainer',
              webLink: '/PowerCodeExplainer',
            },
            {
              webName: 'Power Imitate',
              webLink: '/PowerImitate',
            },
            {
              webName: 'Power FewShot',
              webLink: '/PowerFewShot',
            },
          ]
        },
        // {
        //   groupTittle: 'Products',
        //   groupLinks: [
        //     {
        //       webName: 'Power GrammarFix',
        //       webLink: '/PowerGrammarFix',
        //     },
        //     {
        //       webName: 'Power CodeExplainer',
        //       webLink: '/PowerCodeExplainer',
        //     },
        //     {
        //       webName: 'Power FewShot',
        //       webLink: '/PowerFewShot',
        //     },
        //   ]
        // },
        {
          groupTittle: 'Services',
          groupLinks: [
            {
              webName: 'Pricing',
              webLink: '/Pricing',
            },
            {
              webName: 'About us',
              webLink: '/About',
            },
            {
              webName: 'Contact us',
              webLink: '/Contact',
            },
            // {
            //   webName: 'Blogs',
            //   webLink: '',
            // },
          ]
        },
      ]
    }
  },
  mounted() {

  },

  methods: {

  }

};
</script>

<style scoped>
.external-link {
  text-decoration: none;
  color: black;
}

.external-link:hover {
  text-decoration: underline; 
}
</style>